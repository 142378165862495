import * as React from 'react';
import { SearchBox } from '../search-box/search-box';
import './embedded-search-box.css';
import { BrowserRouter as Router, useCallback, useNavigate } from "react-router-dom";

export const EmbeddedSearchBox = (props) => {
	// Used in homepage and area guides and anywhere else that isn't the search page. Keeps the history push logic seperate from the search pages.
	const navigate = useNavigate();
	const handleSearchLocationChange = React.useCallback((locationLatLong, locationSearchInputText) => {
		navigate('/search?lat=' + locationLatLong.lat + '&long=' + locationLatLong.long + '&lst=' + encodeURIComponent(locationSearchInputText))
		/*
			history.push({
				pathname: '/search',
				search: '?lat=' + locationLatLong.lat + '&long=' + locationLatLong.long + '&lst=' + encodeURIComponent(locationSearchInputText),
				state: { detail: 'some_value' }
			});
		*/
				
		// history.push('/search?lat=' + locationLatLong.lat + '&long=' + locationLatLong.long + '&lst=' + encodeURIComponent(locationSearchInputText))
	}, [navigate]);
		
	return (

			<SearchBox
				searchLocationChange={handleSearchLocationChange}
				selectedCategorySlugs={null}
				locationSearchText=''
				fieldClass={props.fieldClass}
			/>

	)
};

