import * as React from 'react';
import './trip-planner.css';
import { Widget } from '@typeform/embed-react'
import { PopupButton } from '@typeform/embed-react'

import tripSnapshotDesktop from '../../assets/images/trip-planner/snapshot-1-desktop.jpg'
import tripSnapshotMobile from '../../assets/images/trip-planner/snapshot-1-mobile.jpg'

export const TripPlanner = (props) => {
    return (

        <div className="trip-planner">

        <header>
            <div className="container opaque">
                <div>
                    <h2>Receive five personalised trip plans in 24 hours</h2>
                    <div className="h2-subtitle">Our smart trip planner will assemble the perfect adventure based on your preferences</div>
                </div>
                <PopupButton id="khYdwusd" style={{ fontSize: 20 }} className="solid-button">
                    Build me a trip
                </PopupButton>

   
            </div>
            
        </header>
        <div className="snapshots">
            <img className="snapshot-mobile" src={tripSnapshotMobile} alt=""></img>
            <img className="snapshot-desktop" src={tripSnapshotDesktop} alt=""></img>
            </div>
        </div>
    )
}