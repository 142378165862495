import * as React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams
} from "react-router-dom";
import './footer.css';


import TwitterIcon from '../../../assets/logos/twitter.svg';
import FacebookIcon from '../../../assets/logos/facebook.svg';
import InstaIcon from '../../../assets/logos/instagram.svg';
import FooterLogo from '../../../assets/images/wildr-footer-logo.svg';

export const Footer = (props) => {
    return (
        <footer>
            <hr></hr>
            <div className="footer-content">
                <div className="logo-and-contact">
                    <a href="" className="category-card-x">
                        <img className="footer-logo" src={FooterLogo}></img>
                    </a>
                    <p>71-75 Shelton street, London, <br></br>
                        WC2H 9JQ, United Kingdom</p>
                    <a href="mailto:info@wildr.co.uk">info@wildr.co.uk</a>
                    <Link to='/privacy-policy'>Privacy policy</Link>
                    <p>+44 (0)20 8050 1527</p>
                </div>
                <div className="socials">
                    <a target="_blank" rel="noreferrer" href="https://www.instagram.com/wildr_adventures/" className="social">
                        <img src={InstaIcon} alt=""></img>
                    </a>
                    {/*
                    <a href="" className="social">
                        <img src={TwitterIcon} alt=""></img>
                    </a>
                    <a href="" className="social">
                        <img src={FacebookIcon} alt=""></img>
                    </a>
                    */}

                </div>
            </div>
            <p className="copyright-text">© Wildr Ltd. All rights reserved</p>
        </footer>
    )
}
