import * as React from 'react';
import './tp-map.css';
import {
    BrowserRouter as Router,
    Link,
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";
import { MapContainer, TileLayer, Marker, FeatureGroup, useMap, Popup } from 'react-leaflet'
import L from 'leaflet';
import { useMapEvents } from "react-leaflet";
import { mapIcons, mapIconsSelected } from './../mapIcons'
import './tp-map.css';


const group = L.featureGroup();

function Bounds({ activities,accommCoords }) {
    // Only purpose is to set the map bounds to contain the markers
    // Taken from here:
    // https://codesandbox.io/s/how-to-use-bounds-with-dynamic-markers-in-react-leaflet-v3x-g6vim?file=/src/MapLeaflet.jsx:1318-1344
    const map = useMap();

    React.useEffect(() => {
        if (!map || !activities || !accommCoords) return;

        // TODO this seems to run 3-4 x per accommodation - why? probs unneccesary component rerenders. 
        group.clearLayers();
        activities.forEach((marker) => group.addLayer(L.marker([marker.location.latitude, marker.location.longitude])));
        map.fitBounds(group.getBounds(), {
            padding: [25, 25], // Making this higher than 10 or too high can result in the next zoom level being used and zooming out too much. 10 seems an ok compromise
        });
    }, [map, activities, accommCoords]);

    return null;
}


export const TpMap = (props) => {
    const [map, setMap] = React.useState(null); // Do we need this? 
    const handleOnClick = (e) => {
    }

    return (
        <div className="tp-map">
            <MapContainer center={[props.mapFocus.lat, props.mapFocus.long]} zoom={8}
                scrollWheelZoom={true} whenCreated={setMap}>
                {/* <MapEventManager /> */}
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />

                <FeatureGroup>
                    {props.activities && props.activities.filter(activity => activity.feature_in_trips === true).map((object, i) =>
                        <Marker
                            position={[object.location.latitude, object.location.longitude]}
                            icon={props.hoverActivityId === object.id ? mapIconsSelected[object.category.slug] : mapIcons.hasOwnProperty(object.category.slug) ? mapIcons[object.category.slug] : mapIcons['blank']}
                            wildrActivityId={object.id}
                            eventHandlers={{
                                click: (e) => { // event types listed here https://leafletjs.com/reference-1.7.1.html#interactive-layer-click
                                    handleOnClick(e)
                                }
                            }}
                            key={object.id}
                        >
                            <Popup>{object.name}</Popup>
                        </Marker>)
                    }
                    {props.mapHomeCoords && 
                        <Marker zIndexOffset={1000} position={[props.mapHomeCoords.lat, props.mapHomeCoords.long]}>
                            <Popup>{props.accommName}</Popup>
                        </Marker>
                    }
                    {props.activities && <Bounds activities={props.activities.filter(activity => activity.feature_in_trips === true)} accommCoords={props.mapHomeCoords}/>}
                </FeatureGroup>
            </MapContainer>
        </div>
    )
}