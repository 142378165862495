import * as React from 'react';
import './tp-search-proto.css';

import axios from "axios";
import {
    BrowserRouter as Router,
    Link,
    useLocation,
    useNavigate,
    useParams,
    useRouteMatch
} from "react-router-dom";
import { unstable_batchedUpdates } from 'react-dom';
import DirectionsTransitIcon from '@mui/icons-material/DirectionsTransit';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { SearchBox } from '../../components/location-search/search-box/search-box'
import { CategoryFilter } from '../../components/category-filter/category-filter'
import Button from '@mui/material/Button';
import { TripOption } from "../../components/trip-planner/trip-option/trip-option"
// import { Carousel } from '@trendyol-js/react-carousel'; // Flashes and reloads trip options between slides
import { GenericFormModal } from '../../components/generic-form-modal/generic-form-modal';

// Import Swiper React components
import { Swiper, SwiperSlide,  } from 'swiper/react';
import { Navigation, Pagination, EffectCoverflow } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';

import GearImage from '../../../assets/images/misc/alice-donovan-rouse-z9F_yK4Nmf8-unsplash.jpg';

// A custom hook that builds on useLocation to parse the query string for you.
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const TpSearchProto = (props) => {

    const [tripPlans, setTripPlans] = React.useState();
    const [searchLocationCoords, setSearchLocationCoords] = React.useState({ 'lat': 51.5268, 'long': -0.1320 });
    const [selectedCategorySlugs, setSelectedCategorySlugs] = React.useState([]); // 'walk','wild-swim'
    const [isLoadedATrips, setIsLoadedATrips] = React.useState(false);
    const [transportModes, setTransportModes] = React.useState([]);
    const [locationSearchText, setLocationSearchText] = React.useState(null)
    const [queryParamsLoadedToState, setQueryParamsLoadedToState] = React.useState(false)
    const [startDate, setStartDate] = React.useState(null)
    const [endDate, setEndDate] = React.useState(null)
    const [showFilterBar, setShowFilterBar] = React.useState(false)
    const [showFeedbackModal, setShowFeedbackModal] = React.useState(false)
    // Reporting stats
    const [uniqueTripViewCounter, setUniqueTripViewCounter] = React.useState(1) // unique trips viewed
    const [totalTripViewCounter, setTotalTripViewCounter] = React.useState(0) // total trips views
    const [activityViewCounter, setActivityViewCounter] = React.useState(0) // activity views
    const [accomViewCounter, setAccomViewCounter] = React.useState(0) // accomm views

    let query = useQuery();
    let navigate = useNavigate();

    const swiperRef = React.useRef(null);

    const [activeIndex, setActiveIndex] = React.useState(0);

    // When the trip option slider changes, add trip area to url params
    React.useEffect(() => {
        tripPlans && navigate(buildUrlQueryParams())
        tripPlans && setTotalTripViewCounter(totalTripViewCounter + 1)
        // Count the number of unique trip views for reportings
        if(activeIndex + 1 > uniqueTripViewCounter){
            setUniqueTripViewCounter(activeIndex + 1)
        }
        
    }, [activeIndex, tripPlans])


    // If the unqiue trip view count changes, report to MS
    React.useEffect(() => {
        window._mfq.push(["setVariable", `unique-trips-viewed`, uniqueTripViewCounter])
    }, [uniqueTripViewCounter, tripPlans])

    // If the total trip view count changes, report to MS
    React.useEffect(() => {
        totalTripViewCounter > 0 && window._mfq.push(["setVariable", `total-trips-viewed`, totalTripViewCounter])
    }, [totalTripViewCounter, tripPlans])

    // Count and report on total activity views
    const handleAcivityViewed = () => setActivityViewCounter(activityViewCounter +1)
    React.useEffect(() => {
        activityViewCounter > 0 && window._mfq.push(["setVariable", `total-acivities-viewed`, activityViewCounter])
    }, [activityViewCounter, tripPlans])

    // Count and report on total accomm views
    const handleAccommClick = () => setAccomViewCounter(accomViewCounter +1)
    React.useEffect(() => {
        accomViewCounter > 0 && window._mfq.push(["setVariable", `total-accomms-viewed`, accomViewCounter])
    }, [accomViewCounter, tripPlans])

    // On page load check url for any params
    React.useEffect(() => {
        // Move setStates into a batch - this is so we can pull multiple search and filter params from url params, set states, and not trigger a new fetchActivities with each individual state change
        // Now on later react is there inbuilt code for batch?
        unstable_batchedUpdates(() => {
            query.get('lst') && setLocationSearchText(decodeURIComponent(query.get('lst')))
            if (query.get('start') && query.get('end')) {
                setStartDate(query.get('start'))
                setEndDate(query.get('end'))
            }
            query.get('lat') && query.get('long') && setSearchLocationCoords({ 'lat': query.get('lat'), 'long': query.get('long') })
            query.get('cats') && setSelectedCategorySlugs(query.get('cats').split(','))
            query.get('transport') && setTransportModes(query.get('transport').split(','))

            setQueryParamsLoadedToState(true)
        });

        // document.title = "Adventure trip planner - Wildr"
    }, [])

    // Builds a list of query params from search and filter states - called whenever filters are change
    const buildUrlQueryParams = () => {

        let params = "?"
        if (selectedCategorySlugs) params = params + `&cats=${selectedCategorySlugs.join(",")}`
        if (tripPlans) params = params + `&tpid=${tripPlans[activeIndex].area.id}`
        if (transportModes) params = params + `&transport=${transportModes.join(",")}`
        if (locationSearchText) params = params + `&lst=${encodeURIComponent(locationSearchText)}`
        if (searchLocationCoords) params = params + `&lat=${searchLocationCoords.lat}&long=${searchLocationCoords.long}`
        // todo - selected accomm? would need to pull state up
        return (params)
    }

    // On page load, once the query params are in state, do a search
    React.useEffect(() => {
        if (queryParamsLoadedToState) {
            tpSearch()
        }
    }, [queryParamsLoadedToState])

    const tpSearch = () => {
        let url = process.env.REACT_APP_WILDR_PUBLIC_API + `/trip_plan_search/?lat=${searchLocationCoords.lat}&long=${searchLocationCoords.long}
            &category=${selectedCategorySlugs.join(',')}${transportModes.length > 0 ? '&transport=' + transportModes.join(',') : ''}${startDate && endDate ? `&start=${startDate}&end=${endDate}` : ''}`

        setIsLoadedATrips(false)
        axios.get(url).then((response) => {

            unstable_batchedUpdates(() => { // TODO remove ?
                // setCustomer(response.data) // TODO exclude trip plans as store in sep state!
                setTripPlans(response.data)
            })

            setIsLoadedATrips(true)
        }).catch(error => {
            console.log("error", error)
        });
    }

    // After 60 seconds, show the feedback form 
    React.useEffect(() => {
        if (process.env.REACT_APP_SHOW_FEEDBACK_POPUP == 'true') {
        setTimeout(() => {
            setShowFeedbackModal(true)
        }, parseInt(process.env.REACT_APP_FEEDBACK_POPUP_DELAY_MS))
        }
    }, [])

    // Report when someone choses to view filters
    React.useEffect(() => {
        showFilterBar && window._mfq.push(["setVariable", "show-filters", "true"])
    }, [showFilterBar])

    const handleSearchLocationChange = (locationLatLong, locationSearchInputText) => {
        setLocationSearchText(locationSearchInputText)
        setSearchLocationCoords(locationLatLong)
    }

    const handleCategoryChange = (dropdownSelection) => {
        setSelectedCategorySlugs(dropdownSelection.map(item => item.value))
    }

    const handleTripSearch = () => {
        navigate(buildUrlQueryParams())
        tpSearch()
    }

    const handleTransportModeChange = (transportMode) => {
        if (transportModes.includes(transportMode)) {
            // If the selected transport mode is already in the array, remove it
            setTransportModes(transportModes.filter(mode => mode !== transportMode));
        } else {
            // If the selected transport mode is not in the array, add it
            setTransportModes([...transportModes, transportMode]);
        }
    }
    
    const handleCloseFeedbackModal = () => setShowFeedbackModal(false)


    return (
        <>
            {/* Feedback request modal */}
            <GenericFormModal showModal={showFeedbackModal}
                heroImage={GearImage} 
                title={`£20 outdoor gear voucher for your feedback...`} 
                subtitle={<>
                Wildr is a work in progress, to make it great we need your feedback!
                <br></br>
                <br></br>
                In exchange for a 20 minute call to hear your thoughts on the product we’ll give you a £20 voucher to spend at your favourite outdoor retailer.
                <br></br>
                <br></br>
                Limited sessions available, <a href="https://calendly.com/david-wildr/feedback/" target="_blank" >click here</a> to book a slot!
                <br></br>
                <br></br>
                <br></br>
                </>}
                footnote={<>*Up to 20 sessions available<br></br>**One session per household limit<br></br>***£20 voucher will be sent on completion of feedback session</>}
                handleCloseTripEnquiryModal={handleCloseFeedbackModal}
                formComponent={() =>
                <></>
                } 
            />
        
            <section className="trip-plan">
                <div className="container">
                    {/* 
                    <h2>Proto search</h2>
                    <div className="h2-subtitle">Check 'em out</div>
                    */}

                    <div className='filters-button'>
                        <Button color="primary" size="small" onClick={() => setShowFilterBar(!showFilterBar)} >
                            {showFilterBar ? 'Hide filters' : 'Show filters'}
                        </Button>
                    </div>
                    {showFilterBar &&
                        <div className="tp-search-bar">
                            <div className='central-search'>
                                <SearchBox placeholder={'Trip start location'} searchLocationChange={handleSearchLocationChange} locationSearchText={locationSearchText} selectedCategorySlugs={[]} fieldClass={null} />
                                <div className="filters-and-map-toggle">
                                    <CategoryFilter handleCategoryChange={handleCategoryChange} selectedCategorySlugs={selectedCategorySlugs} queryParamsLoadedToState={true} showTripFiltersOnly={true}></CategoryFilter>
                                </div>
                                <div className="transport-modes">

                                    <DirectionsTransitIcon style={{ color: transportModes.includes('transit') ? 'black' : 'grey' }}
                                        onClick={() => handleTransportModeChange('transit')} />
                                    <DirectionsCarIcon style={{ color: transportModes.includes('driving') ? 'black' : 'grey' }}
                                        onClick={() => handleTransportModeChange('driving')} />
                                </div>
                                <button className="solid-button" onClick={handleTripSearch}>
                                    Update
                                </button>
                            </div>
                        </div>
                    }

                    {!isLoadedATrips &&
                        <div className="loader-container">
                            <div className="loader"></div>
                        </div>
                    }

                    {
                        tripPlans && tripPlans.length == 0 && "NO TRIPS FOUND :("
                    }

                    {/* <i className="icon-arrow-long-right review-swiper-button-next">BANG</i> */}


                    {isLoadedATrips && tripPlans != null &&
                        <>
                            <div className="swipe-instruction-text">Swipe to change trip option!</div>
                            <Swiper navigation={true} modules={[Navigation, Pagination, EffectCoverflow ]} className="mySwiper" noSwipingClass="exclude-from-swiping"
                                threshold={40}
                                ref={swiperRef}
                                onRealIndexChange={(element) => setActiveIndex(element.activeIndex)}
                            >
                                {tripPlans.map(function (object, i) {

                                    return (
                                        <SwiperSlide
                                            key={i}
                                            navigation={{
                                                nextEl: '.review-swiper-button-next',
                                                prevEl: '.review-swiper-button-prev',
                                            }}
                                        >
                                            <TripOption tripPlan={object} keyme={i} total={tripPlans.length} handleAcivityViewed={handleAcivityViewed} handleAccommClick={handleAccommClick}/>
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>
                        </>
                    }

                </div>
            </section>
            </>
    )
}

