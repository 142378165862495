import * as React from 'react';
import './about-us.css';
import { TitleBlock } from '../components/title-block';

import aboutUsImage1 from '../../assets/images/about-us/about-us-1.jpg';
import aboutUsImage2 from '../../assets/images/about-us/about-us-2.jpg';
import Session, { SessionAuth } from "supertokens-auth-react/recipe/session";

export const AboutUs = (props) => {
    // React.useEffect(() => document.title = "Our Story | Wildr", [])
    let sessionContext = Session.useSessionContext();

    if (sessionContext.loading) {
        return null;
    }


    return (

        <div className="about-us">

            <header>
                <div className="container">
                    <div>
                        <h2>The go-to place for magical adventures in the UK</h2>
                        <div className="h2-subtitle">Restoring your relationship with the natural world</div>
                    </div>
                </div>
            </header>
            <div className="container image-strip">
            </div>
            <div className="container split">
                <div>
                    <img src={aboutUsImage1 + "?tr=w-850"} alt=""></img>
                </div>

                <div>
                    <h2 className="right-side-text">Story</h2>
                    <div className="h2-subtitle right-side-text">
                    Since the beginning of time, humans felt one with the natural world.
                    <br></br><br></br>
                    We roamed the earth using the stars as our maps and the wind as a guide.
                    <br></br><br></br>
                    When we hopped in a river, we heard the water speak.
                    <br></br><br></br>
                    If a wild animal jumped out of a Bush, we knew what to do.
                    <br></br><br></br>
                    We went on adventures not as a holiday but as a way of life.
                    <br></br><br></br>
                    We knew how to find secret spots.
                    <br></br><br></br>
                    We were natural explorers, curious...
                    <br></br><br></br>
                    We were Wildr.
                    <br></br><br></br>
                    </div>    
                </div>

                {/* 
                Story 


                <div>
                    <h2 className="right-side-text">The idea for Wildr</h2>
                    <div className="h2-subtitle right-side-text">
                        The idea for Wildr was born between two uni friends - Dave and Sam.
                        <br></br><br></br>
                        Dave was on a wild and romantic holiday with his wife Rosie in Devon, keen to explore the local area they found it surprisingly hard to find adventurous things to do. 
                        <br></br><br></br>
                        Meanwhile Sam, a queer creative who loves dancing, moved back to London after 2 years in Devon. Sam felt the pinch of city life - missing easy access to breath-taking coastal walks, magical wild swim spots; sacred sites and memorable saunas in forests. Sam didn’t enjoy spending hours building trips, everytime she wanted a wild getaway.
                        <br></br><br></br>
                        Dave and Sam got together; put their skills together and started working on Wildr...
                        <br></br><br></br>
                    </div>    
                </div>
                */}

            </div>
            <div className="container split">
                <div>
                
                <h2 className="left-side-text">Message from the team</h2>
                    <div className="h2-subtitle left-side-text">
                        Hi, we're Sam and Dave!
                        <br></br><br></br>
                        We met at uni and partied for 5 years together ⚡ Somehow made it out alive. 
                        <br></br><br></br>
                        Today, nothing brings us more joy than being with our families, friends and spending time in the wild.
                        We know that not everyone is confident out in nature. Not everyone feels safe exploring the wild. 
                        <br></br><br></br>
                        And sometimes when we're out in the wild, it's hard to find local activities and magical spots.
                        That's why we're here.
                        <br></br><br></br>
                        Any questions, don't hesitate to email:
                        info@wildr.co.uk 
                    </div>
                </div>
                {/* 
                <h2 className="left-side-text">Our goal at Wildr</h2>
                    <div className="h2-subtitle left-side-text">
                        Make finding adventurous things to do is as easy and tempting as possible.
                        <br></br><br></br>
                        By daring to challenge ourselves, learning something new or simply soaking up the beauty of
                        the natural world we can feel invigorated and refreshed.
                    </div>
                </div>
                */}

                <div>
                    <img src={aboutUsImage2 + "?tr=w-850"} alt=""></img>
                </div>
            </div>
        </div>


    )
}

