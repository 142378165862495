import React from 'react';
import './trip-option.css';
import { JourneyTimesTpActivity } from "../../journey-times-tp-activty/journey-times-tp-activity"
import { TpMap } from "../../map/tp-map/tp-map"
import { TripItem } from "../trip-item/trip-item"
import { JourneyTimesTpOption } from "../../journey-times-tp-option/journey-times-tp-option"
import HotelRoundedIcon from '@mui/icons-material/HotelRounded';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import RefreshIcon from '@mui/icons-material/Refresh';
import CrossfadeImage from 'react-crossfade-image'

import { GenericFormModal } from '../../generic-form-modal/generic-form-modal';
import {TripEnquryForm} from '../../generic-form-modal/trip-enquiry-form-to-email'
// import {FeedbackForm} from '../../generic-form-modal/feedback-form-to-email-UNUSED'


import {
  BrowserRouter as Router,
  Link,
  useLocation,
  useNavigate,
  useParams,
  useRouteMatch
} from "react-router-dom";


const ActionButtons = (props) => {

  const handleOtherButtonClick = (e) => {
    console.log(e.target.innerText)
    window._mfq.push(["setVariable", `${e.target.innerText}-click`, "true"])
    alert("Feature coming soon")
  }

  return(
    <div className='trip-action-buttons'>
    <button className="pill-button secondary condensed ms-track-avail-button" onClick={props.handlePricingButtonClick}>
      Pricing + availability &#x1F4B8;
    </button>
    <button className="pill-button secondary condensed ms-track-avail-button" onClick={handleOtherButtonClick}>
      Share &#x1F517;
    </button>
    <button className="pill-button secondary condensed ms-track-avail-button" onClick={handleOtherButtonClick}>
      Customise this trip &#x1f9e9;
    </button>
    <button className="pill-button secondary condensed ms-track-avail-button" onClick={handleOtherButtonClick}>
      Like &#x2764;&#xFE0F;
    </button>
  </div>
  )
}

export const TripOption = (props) => { 
  const [showNonFeaturedActivities, setShowNonFeaturedActivities] = React.useState(false);
  const [selectedAccomm, setSelectedAccomm] = React.useState(null);
  const [showTripEnquiryModal, setShowTripEnquiryModal] = React.useState(false);
  console.log('selectedAccomm', selectedAccomm)
  let location = useLocation();

  React.useEffect(() => {
    setSelectedAccomm(props.tripPlan.accommodation[0])
  }, [])

  const handlePricingButtonClick = () => {
    window._mfq.push(["setVariable", "price-click", "true"])
    setShowTripEnquiryModal(true)
  }


  const imageWidthPx = 400
  const getTravelTimeToActivity = (activityId) => {
    /* Travel times are stored in the accomm object, not the activities list
    this method get's the travel time for a given activity from the accommodation list */
    let travelTimeToActivity = {}

    if (selectedAccomm) {
      selectedAccomm.travel_time_to_activities.forEach((item) => {
        if (item.activity_id == activityId) {
          travelTimeToActivity = item
        }
      })

    }
    return travelTimeToActivity
  }

  const handleNextAccomm = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let accommIndex = props.tripPlan.accommodation.indexOf(selectedAccomm)
    if (accommIndex < props.tripPlan.accommodation.length - 1) {
      setSelectedAccomm(props.tripPlan.accommodation[accommIndex + 1])
    }
    else (
      setSelectedAccomm(props.tripPlan.accommodation[0])
    )
    window._mfq.push(["setVariable", `accomm-refresh`, "true"])
  }

  const handleCloseTripEnquiryModal = () => setShowTripEnquiryModal(false)
  return (
    <>
      {/* Trip enquiry modal */}
      <GenericFormModal showModal={showTripEnquiryModal}
        heroImage={props.tripPlan.area.image} 
        title={`${props.tripPlan.area.title} trip caught your eye?`} 
        subtitle={'Enter your details and one of our team will get back to you today with availability and pricing.'}
        handleCloseTripEnquiryModal={handleCloseTripEnquiryModal}
        formComponent={(tripArea=props.tripPlan.area, handleCloseTripEnquiryModal) =>
          <TripEnquryForm
            selectedAccomm={selectedAccomm}
            tripArea={tripArea}
            closeModal={handleCloseTripEnquiryModal}
          />
        } 
      />

      <div className="trip-option">
        <div className="option-counter">Option {props.keyme + 1} / {props.total}</div>
        
        <div className="trip-header">
        <div className="trip-title-block">
          <div>
            <h3>{props.tripPlan.area.title} trip</h3>
            { /* <p>Mon 23rd - Fri 27th May 2022</p>*/}
          </div>
          { /* <p>~£720</p>  */}

          {selectedAccomm &&
            <JourneyTimesTpOption travelTime={selectedAccomm.travel_time} transitRouteUrl={null} drivingRouteUrl={null} />
          }
          <div onClick={() => setShowNonFeaturedActivities(prevCheck => !prevCheck)}>
            {showNonFeaturedActivities ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
          </div>
        </div>
        <div className='bookit desktop-only'>
          <ActionButtons handlePricingButtonClick={handlePricingButtonClick}/>
        </div>
        </div>


        <div className="trip-components">
          <div className="trip-card-wrapper">
            <div className="image-container">
              <div className="destination-card-text">
                <div>{props.tripPlan.area.title}</div>
              </div>
              {/*<img src ="https://media.istockphoto.com/photos/clovelly-picture-id468048704?k=20&m=468048704&s=612x612&w=0&h=qtJCr6thJmUZPwuWxZ67FxS-PcBfMTmudgDOGuOxd5U="></img>  */}
              <img src={`${props.tripPlan.area.image}?tr=w-${imageWidthPx}`}></img>
              
            </div>
          </div>

          {selectedAccomm &&
            <>
              {/* <Link className="trip-card-wrapper" to={{ pathname: selectedAccomm.external_url}} target="_blank"> 
            Disabled this as a is easier for linking out to external urls
            */}
            <Link to={`/stays/` + selectedAccomm.id} key={selectedAccomm.id.toString()} 
          className={`trip-card-wrapper`} 
          data-activity-id={selectedAccomm.id} onClick={props.handleAccommClick} 
          state={{ background: location ,name: selectedAccomm.title, travelTime: null, travelTimeLabel: 'Travel times from your accommodation' }}
        >
              {/* <a className="trip-card-wrapper" href={selectedAccomm.external_url} target="_blank" rel="noreferrer"> */}
                
                <div className="image-container">
                  {/* <img src ="https://media.istockphoto.com/photos/clovelly-picture-id468048704?k=20&m=468048704&s=612x612&w=0&h=qtJCr6thJmUZPwuWxZ67FxS-PcBfMTmudgDOGuOxd5U="></img>*/}
                  
                  <CrossfadeImage src={selectedAccomm.images.length > 0 && `${selectedAccomm.images[0].url}?tr=w-${imageWidthPx}`} />
                  {props.tripPlan.accommodation.length > 0 && <div className="overlay-text top" onClick={handleNextAccomm}><div><RefreshIcon   /></div></div>}
                  <div className="overlay-text bottom">
                    <div><span><HotelRoundedIcon />{selectedAccomm.price_per_night && `From £${parseFloat(selectedAccomm.price_per_night).toFixed(0)}/night`}</span></div>
                    <div>{selectedAccomm.title}</div>
                  </div>
                </div>
              
              </Link>
            </>
          }

        <div className='bookit mobile-only'>
          <ActionButtons handlePricingButtonClick={handlePricingButtonClick}/>
        </div>

          {props.tripPlan.activities.map(function (activity, i) {
            return (
              <TripItem activity={activity} key={i} imageWidthPx={imageWidthPx} activtyTravelTime={getTravelTimeToActivity(activity.id)} showNonFeaturedActivities={showNonFeaturedActivities} handleAcivityViewed={props.handleAcivityViewed}/>
            )
          })}

          {selectedAccomm &&
            <div className="trip-card-wrapper exclude-from-swiping" >
              
              <div className="image-container">
                <TpMap mapFocus={{ lat: selectedAccomm.location.latitude, long: selectedAccomm.location.longitude }}
                  mapHomeCoords={{ lat: selectedAccomm.location.latitude, long: selectedAccomm.location.longitude }}
                  activities={props.tripPlan.activities}
                  accommName={selectedAccomm.title}
                />
              </div>
            </div>
          }
        </div>
      </div>

    </>
  )
}