import React from 'react';

export function StaticLocationBar(props) {
    return (
    <div className='static-location-bar'>
        <div className="location-title-area">
        <div className="image-cropper">
            <img className='location-pic' src={props.staticLocation.hero_image}></img>
        </div>
            <div className="location-title">
                <h1>{props.staticLocation.name} area guide</h1>
                <h3>{props.staticLocation.address_summary_parish_ward}</h3>
                <a href={props.staticLocation.external_url} target='_blank'>{props.staticLocation.external_url}</a>
            </div>
        </div>

    </div>
    )
}
