import * as React from 'react';
import './activities-search-page.css';
import { ActivitiesList } from './components/results-list'
import { StaticLocationBar } from './components/static-location-bar'
import { FiltersBar } from './components/filters-bar'
import { MapComponent } from './components/map/map'
import { SearchBox } from './components/location-search/search-box/search-box'
import { CategoryFilter } from './components/category-filter/category-filter'
import { Menu } from './components/menu/menu'
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import ContactlessIcon from '@mui/icons-material/Contactless';
import { ReactComponent as WildrLogo } from '../assets/images/wildr-orange.svg';
import { ReactComponent as MapIcon } from '../assets/icons/map.svg';
import { ReactComponent as ListIcon } from '../assets/icons/list.svg';
import axios from "axios";
import { createMedia } from "@artsy/fresnel"
import L from 'leaflet';
import ReactGA from "react-ga4";
import {
  BrowserRouter as Router,
  Link,
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";
import debounce from 'lodash.debounce';
import { unstable_batchedUpdates } from 'react-dom';
import analytics from "./../analytics"

// https://github.com/artsy/fresnel
const { MediaContextProvider, Media } = createMedia({
  // breakpoints values can be either strings or integers
  breakpoints: {
    sm: 0,
    md: 505,
    lg: 1024,
    xl: 1192,
  },
})


// A custom hook that builds on useLocation to parse the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}


export const ActivitiesSearchPage = (props) => {

  const [searchLocationCoords, setSearchLocationCoords] = React.useState(null)

  /* 
  Jul 22 - added 'zoom' to mapFocus. Did this in the process of adding support to the map without searching from a particular location.
    Main reason: zoom level is not an actively tracked live prop for leaflet map, meaning if we want a different zoom level from when leaflet is initiated, 
    we have to use the flyTo function whenever zoom OR map focus lat long changes. It's eaier to track if either changes by including them in the same state object.
    In two stats, location and zoomLevel, the flyTo would often we called twice. This avoid that and was the easiest approach. Did try debounce, tracking if either state changes etc but failed.
  */
  const [mapFocus, setMapFocus] = React.useState(null) // { 'lat': 54.440451, 'long': -2.197429 , 'zoom': 11}
  // const [zoomLevel, setZoomLevel] = React.useState(11); // TODO remove this? OR want to track zoom level?

  const [activities, setActivites] = React.useState([]);
  const [loadActivitiesError, setLoadActivitiesError] = React.useState(null);
  const [isLoadedActivities, setIsLoadedActivities] = React.useState(false);
  const [isLoadedStaticLocation, setisLoadedStaticLocation] = React.useState(false);
  const [isLoadedStaticLocationError, setisLoadedStaticLocationError] = React.useState(null);
  const [hoverActivityId, setHoverActivityId] = React.useState(null)
  const [hoverActivityOriginatedInMap, setHoverActivityOriginatedInMap] = React.useState(false) // Used to track where the last highlighting of an activity (due to moseover) happened (either map or list) - in turn used to only enable auto scrolling into view in list if originated from the map
  const ActivitiesListRef = React.useRef(null)
  const [staticLocation, setStaticLocation] = React.useState(null)
  const [locationSearchText, setLocationSearchText] = React.useState(null)
  const [useMapView, setUseMapView] = React.useState(false)
  const [isLoadedTransitTransport, setIsLoadedTransitTransport] = React.useState(false)
  const [govBathingWaterQualityList, setGovBathingWaterQualityList] = React.useState(null)
  const [selectedCategorySlugs, setSelectedCategorySlugs] = React.useState(null);
  const [categories, setCategories] = React.useState([]);
  const [isLoadedCategories, setIsLoadedCategories] = React.useState(false);
  const [isLoadedCategoriesError, setIsLoadedCategoriesError] = React.useState(null);
  const [queryParamsLoadedToState, setQueryParamsLoadedToState] = React.useState(false)
  const [searchBounds, setSearchBounds] = React.useState(null)
  const [mapBounds, setMapBounds] = React.useState(null)
  const [updateSearchButtonEnabled, setUpdateSearchButtonEnabled] = React.useState(false) // TBC rename searchUpdatesAvailable if needed more globally? 
  const [searchAsMapMovechecked, setSearchAsMapMovechecked] = React.useState(false);

  let query = useQuery();
  let navigate = useNavigate();
  let { staticLocationID } = useParams();
  let location = useLocation();

  let defaultZoomInLevel = 11
  let defaultZoomOutLevel = 6

  let searchLocationToBoundsPadding = 60000 // sizeInMeters meters apart from the LatLng.

  // static location is no longer used but check with dave before removing
  const fetchStaticLocationDetail = () => {
    setisLoadedStaticLocation(false)
    let url = process.env.REACT_APP_WILDR_PUBLIC_API + "/static_location/" + staticLocationID + "/"
    axios.get(url).then((response) => {
      setStaticLocation(response.data)
      setisLoadedStaticLocation(true)
      setSearchLocationCoords({ 'lat': response.data.location.latitude, 'long': response.data.location.longitude })
      setMapFocus({ 'lat': response.data.location.latitude, 'long': response.data.location.longitude, 'zoom': 11 })
      document.title = "Adventurous things to do - Wildr"
    }).catch(error => {
      console.log("error", error)
      setisLoadedStaticLocationError(error)
    });
  }


  const fetchActivities = () => {
    setIsLoadedActivities(false);

    if (searchLocationCoords) {
      //setMapFocus({lat:searchLocationCoords.lat, long:searchLocationCoords.long, zoom: 11})
    }
    else if (!searchBounds) {
      setMapFocus({ 'lat': 54.440451, 'long': -2.197429, 'zoom': defaultZoomOutLevel })
    }

    let resultLimit = 200
    // If search lat / long, we use search endpoint, if not we use activites endpoint. Refactor into one in future.
    var urly = (searchLocationCoords) ?
      process.env.REACT_APP_WILDR_PUBLIC_API + "/search?lat=" + searchLocationCoords['lat'] + "&long=" + searchLocationCoords['long'] + "&transport=driving" +
      "&fields=name,category,location,truncated_description,images,external_images,external_url,source,pricing,partner_id,natural_next_bookable_day,txgb_provider,travel_time&limit=" + resultLimit :
      process.env.REACT_APP_WILDR_PUBLIC_API + "/activities?" +
      "fields=name,category,location,truncated_description,images,external_images,external_url,source,pricing,partner_id,natural_next_bookable_day,txgb_provider&limit=" + resultLimit

    urly = searchBounds ? `${urly}&swlat=${searchBounds._southWest.lat}&swlong=${searchBounds._southWest.lng}&nelat=${searchBounds._northEast.lat}&nelong=${searchBounds._northEast.lng}` : urly
    if (selectedCategorySlugs) {
      urly = urly + "&category=" + selectedCategorySlugs.join(',')
    }

    fetch(urly)
      .then(res => res.json())
      .then(
        (result) => {
          setActivites(result)
          setIsLoadedActivities(true);
          setUpdateSearchButtonEnabled(false)

          // GA stuff - do result count? 
          if (!searchLocationCoords){
            ReactGA.event("explore", {
              search_bounds: searchBounds ? [searchBounds._southWest.lat, searchBounds._southWest.lng, searchBounds._northEast.lat, searchBounds._northEast.lng] : null,
              selected_category_slugs: selectedCategorySlugs ? selectedCategorySlugs : null 
            });
          }

        },
        (error) => {
          setLoadActivitiesError(error);
        }
      )
  }

  // Whenever search location is set, fetch activities
  React.useEffect(() => {
    if (queryParamsLoadedToState) {
      navigate(buildUrlQueryParams())
      fetchActivities();
    }
  }, [searchBounds, selectedCategorySlugs, queryParamsLoadedToState])


  const searchWithinBounds = (data) => {
    setSearchBounds(data.pad(.1)) // Pad the width of the existing viewport x 0.1 . We don't want to pad too much if it means we don't show all the results? 

  }

  // Builds a list of query params from search and filter states - called whenever a search or filter parameter changes and use to set navigate / set history
  // TBC do we want to exclude this from google analytics page view? probably. 
  const buildUrlQueryParams = (searchLocCoords = searchLocationCoords, selectedActId = null) => {
    let params = "?"
    if (searchLocationCoords) { params = params + `lat=${searchLocCoords.lat}&long=${searchLocCoords.long}` }
    if (selectedActId) { params = params + `&said=${selectedActId}` }
    if (locationSearchText) { params = params + `&lst=${encodeURIComponent(locationSearchText)}` }
    if (selectedCategorySlugs) { params = params + `&cats=${selectedCategorySlugs.join(",")}` }
    return (params)
  }

  const mergeTravelTimes = (originalActivities, additionalActivityTravelTimes) => {
    // We do a seperate query to fetch more travel modes as we use two different APIs
    const arrr = []
    originalActivities.forEach(item => {
      arrr.push(item)

    });
    additionalActivityTravelTimes.forEach(item => {
      arrr.find(x => x.id === item.id)['travel_time'] = { ...arrr.find(x => x.id === item.id)['travel_time'], ...item['travel_time'] }
    });
    return (arrr)
  }

  // Once initial activities are loaded, get more transport times, gov bathing qual.
  React.useEffect(() => {
    if (isLoadedActivities) {
      const transportMode = 'transit'
      const activityIds = []
      const govBathingSpotsActivityIds = []
      activities.results.forEach(item => {
        // Push to list to fetch other transport mode travel times
        activityIds.push(item.id)
        // If  gov bathing activities, fetch extra data
        if (item.source && item.source.slug === 'gov-bathing') { govBathingSpotsActivityIds.push(item.id) }
      });
      if (searchLocationCoords) {
        if (activityIds) getSecondaryTransportTravelTimes(activityIds, transportMode)
        if (activityIds) getSecondaryTransportTravelTimes(activityIds, 'cycling')
        if (activityIds) getSecondaryTransportTravelTimes(activityIds, 'walking')
      }
      if (govBathingSpotsActivityIds) getGovBathingWaterQualityList(govBathingSpotsActivityIds)
    }
  }, [isLoadedActivities])

  // Fetch official bathing water quality results from the UK Government API
  const getGovBathingWaterQualityList = () => {
    let url = `${process.env.REACT_APP_WILDR_PUBLIC_API}/bathing-water-proxy?_pageSize=1500&_view=basic&_format=json&_properties=latestComplianceAssessment.complianceClassification.name,eubwidNotation`
    axios.get(url).then((response) => {
      setGovBathingWaterQualityList(response.data.result.items)
    }).catch(error => {
      console.log("getGovBathingWaterQualityList error", error)
    });
  }

  // Get more transit time - to keep loading times quick the first query includes driving times only, then we add other transit modes in subsequent queries
  const getSecondaryTransportTravelTimes = (activityIds, transportMode) => {
    setIsLoadedTransitTransport(false)
    let url = process.env.REACT_APP_WILDR_PUBLIC_API + "/search?lat=" + searchLocationCoords['lat'] + "&long=" + searchLocationCoords['long'] + "&fields=id,travel_time&transport=" + transportMode + "&id=" + activityIds
    axios.get(url).then((response) => {
      setIsLoadedTransitTransport(true)
      const merge = mergeTravelTimes(activities.results, response.data.results)
      setActivites({ ...activities, results: merge })
    }).catch(error => {
      console.log("getSecondaryTransportTravelTimes error", error)
      setisLoadedStaticLocationError(error)
    });
  }


  // On page load this fetches activities
  React.useEffect(() => {

    // If static locaton id is present in the url, then use this to pull the location (eg a rental property)info first. 
    // staticLocation is legacy code that is no longer used - speak to Dave befor removing though
    if (staticLocationID) {
      fetchStaticLocationDetail()
    }
    else {
      // Aug 22 moved setStates into a batch - this is so we can pull multiple search and filter params from url params, set states, and not trigger a new fetchActivities with each individual state change
      unstable_batchedUpdates(() => {
        if (query.get('lst')) {
          setLocationSearchText(decodeURIComponent(query.get('lst')))
        }
        if (query.get('lat') && query.get('long')) {
          setSearchLocationCoords({ 'lat': query.get('lat'), 'long': query.get('long') })
          let latlng = L.latLng(query.get('lat'), query.get('long'));
          setSearchBounds(latlng.toBounds(searchLocationToBoundsPadding))
          setMapFocus({ 'lat': query.get('lat'), 'long': query.get('long'), 'zoom': defaultZoomInLevel })
        }
        if (query.get('cats')) {
          setSelectedCategorySlugs(query.get('cats').split(','))
        }

        setQueryParamsLoadedToState(true)
      });
    }
    // TODO reconsider document titles
    document.title = "Adventure trip planner - Wildr"
  }, [])



  const searchBoundsContainMapBounds = (mapBounds) => {
    if (searchBounds) {
      if (searchBounds.contains(mapBounds)) {
        return true
      }
      else {
        return false
      }
    }
  }


  React.useEffect(() => {
    if (searchAsMapMovechecked && searchAsMapMovechecked === true) {
      refreshResultsBasedOnMap()
    }
  }, [searchAsMapMovechecked]);

  React.useEffect(() => {
    if (mapBounds) {
      refreshResultsBasedOnMap()
    }
  }, [mapBounds]);


  const refreshResultsBasedOnMap = () => {
    if (isLoadedActivities) { // TODO there's a gap where user can scroll map and this is false. How to avoid?

      // If search as I move enabled, update search bounds state - which in turn triggers a new search
      if (searchAsMapMovechecked && (searchBoundsContainMapBounds(mapBounds) === false || activities.next !== null)) {
        setSearchBounds(mapBounds) 
      }

      // If no search bounds exist - eg page just opened on while UK OR if the search bounds don't contain the view OR if there are more activites that can be loaded
      //  enable the update search button
      if (!searchAsMapMovechecked) {
        if (!searchBounds || searchBoundsContainMapBounds(mapBounds) === false || activities.next !== null) {
          // setSearchBounds(mapBounds) 
          setUpdateSearchButtonEnabled(true)
        }
      }

    }
    else {
      // console.log('No search bounds')
    }
  }


  const handleSearchLocationChange = (locationLatLong, locationSearchInputText) => {
    setLocationSearchText(locationSearchInputText)
    setMapFocus({ 'lat': locationLatLong.lat, 'long': locationLatLong.long, 'zoom': defaultZoomInLevel }) // Why doesn't this win? 
    setSearchLocationCoords(locationLatLong)
    let latlng = L.latLng(locationLatLong.lat, locationLatLong.long);
    setSearchBounds(latlng.toBounds(searchLocationToBoundsPadding))
  }

  const handleCategoryChange = (dropdownSelection) => {
    setSelectedCategorySlugs(dropdownSelection.map(item => item.value))
  }

  const activityMouseEnterHandler = (e) => {
    setHoverActivityOriginatedInMap(false)
    setHoverActivityId(parseInt(e.currentTarget.getAttribute("data-activity-id")))
  }

  const activityMouseLeaveHandler = (e) => {
    setHoverActivityId(null)
  }

  const activityMarkerMouseEnterHandler = (e) => {
    setHoverActivityOriginatedInMap(true)
    var markerWildrId = parseInt(e.target.options.wildrActivityId);
    setHoverActivityId(markerWildrId)

  }
  const activityMarkerMouseLeaveHandler = (event) => {
    debouncedHandleMouseEnterMarker.cancel()
    setHoverActivityId(null)
  }

  const debouncedHandleMouseEnterMarker = React.useCallback(debounce((thingyRef) => {
    if (ActivitiesListRef.current != null) {
      if (thingyRef.current) {
        ActivitiesListRef.current.scrollTo(0, thingyRef.current.offsetTop - 76)
      }

    }
  }, 800), [])

  const handleSelectedActivityChangeFromList = (event) => {
    const aid = parseInt(event.currentTarget.getAttribute("data-activity-id"));
    const selectedActivityData = activities.results.filter(obj => {
      return obj.id === aid
    })[0]
    setMapFocus({ 'lat': selectedActivityData.location.latitude, 'long': selectedActivityData.location.longitude, 'zoom': defaultZoomInLevel })
  }

  const handleHighlightedActivityOutOfView = (thingyRef) => {
    // If an actvity is highlighted by mouseover on the map and it's out of view, this scrolls it into view
    // Toto call debouncedHandleMouseEnterMarker instead
    debouncedHandleMouseEnterMarker(thingyRef)
  }


  return (
    <main className={!useMapView ? "main-with-static-location-bar" : "map-view-layout main-with-static-location-bar"} style={{ 'overflow': location.pathname === '/search' && !useMapView ? 'scroll' : 'hidden' }}>
      <MediaContextProvider>

        {/* On mobile the search and filters are placed outside the header */}
        <Media lessThan="lg" style={{ display: 'contents' }}>
          {!staticLocationID &&
            <div className='central-search'>
              <SearchBox searchLocationChange={handleSearchLocationChange} locationSearchText={locationSearchText} selectedCategorySlugs={selectedCategorySlugs} fieldClass={null}/>
              <div className="filters-and-map-toggle">
                <CategoryFilter handleCategoryChange={handleCategoryChange} selectedCategorySlugs={selectedCategorySlugs} queryParamsLoadedToState={queryParamsLoadedToState} query={query} className=""></CategoryFilter>
                <div className="list-map-toggle-button" onClick={() => setUseMapView(!useMapView)}>
                  {useMapView ? <ListIcon className='list-icon' /> : <MapIcon className='map-icon' />}
                </div>
              </div>
            </div>
          }
          <div className="navbar horizontal-drop-shadow opaque">
            <div className='navbar-container'>
              <Link to="/"><WildrLogo className="wildr-logo" /></Link>
              <Menu transparentMode={false} transparentNavbar={false} isLoggedIn={props.isLoggedIn}></Menu>
              {/* Disabled in CSS too? <div className="tranport-modes"><DirectionsWalk className="tranport-mode-icon" /><DirectionsBike className="tranport-mode-icon" /><DriveEta className="tranport-mode-icon" /></div>*/}
            </div>
          </div>
        </Media>

        {/* On desktop the search and filters are placed inside the header */}
        <Media greaterThanOrEqual="lg" style={{ display: 'contents' }}>
          <div className="navbar horizontal-drop-shadow opaque">
            <div className='navbar-container'>
              <Link to="/"><WildrLogo className="wildr-logo" /></Link>
              <div className='central-search'>
                <SearchBox searchLocationChange={handleSearchLocationChange} locationSearchText={locationSearchText} selectedCategorySlugs={selectedCategorySlugs} fieldClass={null}/>
                <div className="filters-and-map-toggle">
                  <CategoryFilter handleCategoryChange={handleCategoryChange} selectedCategorySlugs={selectedCategorySlugs} queryParamsLoadedToState={queryParamsLoadedToState} query={query} className=""></CategoryFilter>
                  <div className="list-map-toggle-button" onClick={() => setUseMapView(!useMapView)}>
                    {useMapView ? <ListIcon className='list-icon' /> : <MapIcon className='map-icon' />}
                  </div>
                </div>
              </div>
              <Menu transparentMode={false} transparentNavbar={false} isLoggedIn={props.isLoggedIn}></Menu>
            </div>
            {/* Disabled in CSS too? <div className="tranport-modes"><DirectionsWalk className="tranport-mode-icon" /><DirectionsBike className="tranport-mode-icon" /><DriveEta className="tranport-mode-icon" /></div>*/}
          </div>
        </Media>

      </MediaContextProvider>


      {staticLocation && <StaticLocationBar staticLocation={staticLocation} />}

      <FiltersBar />

      {/* Ideally don't include the list at all if useMapView, but this breaks some features so we just display none it 
      // TODO we pass searchLocationCoords and selectedCategorySlugs to results container purely to parse a few no results msgs, move logic out here?
      */}
      <span className="result-container" ref={ActivitiesListRef} style={{ 'display': useMapView && 'none' }}>
        {isLoadedActivities ? <ActivitiesList activities={activities} waterQualityList={govBathingWaterQualityList}
          hoverActivityId={hoverActivityId} clickHandler={handleSelectedActivityChangeFromList} activityMouseEnterHandler={activityMouseEnterHandler}
          activityMouseLeaveHandler={activityMouseLeaveHandler} handleHighlightedActivityOutOfView={handleHighlightedActivityOutOfView}
          hoverActivityOriginatedInMap={hoverActivityOriginatedInMap} searchLocationCoords={searchLocationCoords} selectedCategorySlugs={selectedCategorySlugs} /> :
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        }
      </span>

      {mapFocus &&  <MapComponent activities={activities} hoverActivityId={hoverActivityId}
        mapFocus={mapFocus} mapHomeCoords={searchLocationCoords && searchLocationCoords}
        activityMarkerMouseEnterHandler={activityMarkerMouseEnterHandler} activityMarkerMouseLeaveHandler={activityMarkerMouseLeaveHandler} useMapView={useMapView}
        handleMapFocusChange={setMapFocus} handleSearchWithinBounds={searchWithinBounds} handleMapBoundsChange={setMapBounds} updateSearchButtonEnabled={updateSearchButtonEnabled}
        handleSearchAsMapMoveToggle={setSearchAsMapMovechecked} searchAsMapMovechecked={searchAsMapMovechecked}
      />}

    </main>
  );
}



