import * as React from 'react';
import * as Ariakit from "@ariakit/react";
import './beta-signup-modal.css';

import {
  BrowserRouter as Router,
  Link,
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";
import { Navigate } from "react-router-dom";
import MailchimpSubscribe from "react-mailchimp-subscribe"
import ReactGA from "react-ga4";
import newsletterImage from '../../../assets/images/email-popup/roberto-nickson-iWzpYUSxIu4-unsplash_reduced.jpg';
import closeIcon from '../../../assets/icons/popup-close-white.svg';

// a basic form
// some taken from official docs, some from here https://dev.to/gedalyakrycer/create-an-email-list-with-react-mailchimp-965#step6
const CustomForm = ({ status, closeModal, message, onValidated }) => {

  const [email, setEmail] = React.useState('');

  const submit = () =>
    email &&
    email.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email
    });

  React.useEffect(() => {
    if (status === "success") {
      clearFields()
      ReactGA.event("beta-signup-success-popup", {
      });
    };
  }, [status])


  const clearFields = () => {
    setEmail('')
    setTimeout(() => {
      closeModal()
    }, 2500)
  }

  const handleChange = event => {
    setEmail(event.target.value);
  };


  return (
    <div className='newsletter-form'
      style={{
        borderRadius: 2,
        padding: 10,
        display: "inline-block"
      }}
    >
      {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
      {status === "error" && (
        <div
          style={{ color: "white" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div
          style={{ color: "white" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      <br />
      <input
        label="Email"
        onChange={handleChange}
        // ref={node => (email = node)} breaks with Error: "email" is read-only
        type="email"
        value={email}
        placeholder="your@email.com"
        isRequired
      />
      <br />
      <button className="orange-filled-button" style={{ cursor: "pointer" }} onClick={submit}>
        Submit
      </button>
      <br />
      {/* 
      <div className='nope' onClick={closeModal}>
        No thanks
      </div>
      */}
    </div>
  );
};


export const BetaSignupModal = (props) => {
  const url = "https://wildr.us20.list-manage.com/subscribe/post?u=5744114d84578e062b17229ee&amp;id=cb4ad3dc7b";

  const [visible, setVisible] = React.useState(true);

  const close = () => setVisible(false);

  let navigate = useNavigate();
  let { id } = React.useRef(null);

  function onDismiss() {
    document.title = "Adventure trip planner - Wildr"
    navigate('/')
  }


  return (

      <Ariakit.Dialog className="newsletter-dialog beta-signup" open={true} onClose={props.handleCloseClick}>      
            {/* TODO SWAP open={true} */}
          <div className='newsletter-popup'>  
        <div className='newsletter-popup-container'>
          <div className="split">
            <div className="popup-close-icon" onClick={props.handleCloseClick}>
                  <img className="" src={closeIcon}></img>
                </div>
            <div className="newsletter-image one">
              <img src={newsletterImage +"?tr=w-850"} alt=""></img>
            </div>
            <div className="newsletter-main two">
              <div className="newsletter-info">
                
                <h1>Early bird</h1>
                <p>Gets the worm</p>
                <p>Walk on the wildr side. Get early access to our magical trips. Enter your email below + we’ll be in touch</p>

                <MailchimpSubscribe 
                  url={url}
                  render={({ subscribe, status, message }) => (
                    <CustomForm
                      status={status}
                      closeModal={close}
                      message={message}
                      onValidated={formData => subscribe(formData)}
                    />
                  )}
                />
              </div>

            </div>
          </div>
          
      </div>
      

    </div>

      

      </Ariakit.Dialog>

  );
}
