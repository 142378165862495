import * as React from 'react';
import { Form } from "@quillforms/renderer-core";
import "@quillforms/renderer-core/build-style/style.css";
import { registerCoreBlocks } from "@quillforms/react-renderer-utils";
import "./trip-form.css";
import axios from "axios";
import "./location-block";
import "./dates-block";
import { BrowserRouter as Router, useCallback, useNavigate } from "react-router-dom";
import moment from 'moment';


import customiseScreen from '../../../assets/images/landing-page/custom_social.jpg?tr=w-850';
import fiveOptionsDesktop from '../../../assets/images/landing-page/options_l.jpg'
import fiveOptionsMobile from '../../../assets/images/landing-page/options_m.jpg?tr=w-850'
import fanSpreadScreen from '../../../assets/images/landing-page/fan-spread.jpg'
import bookScreen from '../../../assets/images/landing-page/book.jpg'
import aboutYou from '../../../assets/images/landing-page/aboutyou.jpg'
import robAndDave from '../../../assets/images/landing-page/rob-and-dave-flip.png'
import socialScreen from '../../../assets/images/landing-page/social.jpg'

/*
Quillforms

A bit more info about React, themes etc: https://opensource.quillforms.com/
Also there is a discord channel
and some codepens

*/

registerCoreBlocks();
export const TripForm = (props) => {



  const [categories, setCategories] = React.useState([]);
  const [isLoadedCategories, setIsLoadedCategories] = React.useState(false);
  const [isLoadedCategoriesError, setIsLoadedCategoriesError] = React.useState(null);

  const fetchCategories = () => {
    setIsLoadedCategories(false)
    let url = process.env.REACT_APP_WILDR_PUBLIC_API + "/activity_categories"
    axios.get(url).then((response) => {
      // filter the response.data list to only include categories that have use_as_trip_filter = true
      if (props.showTripFiltersOnly) {
        let filteredCategories = response.data.filter(category => category.use_as_trip_filter == true)
        setCategories(filteredCategories)
      }
      else (
        setCategories(response.data)
      )
      setIsLoadedCategories(true)
    }).catch(error => {
      console.log("error", error)
      setIsLoadedCategoriesError(error)
    });
  }


    const blocks = [
      
      {
        name: "welcome-screen",
        id: "jg1401r",
        attributes: {
          label: "We're Rob and Dave, the founders of Wildr",
          description: "We’re on a mission to revolutionise adventure holiday planning. Normally it takes hours, but we believe it should be easy and inspiring!",
          buttonText: "Continue",
          attachment: {
            type: "image",
            url:
            robAndDave

          }
        }
      },
      {
        name: "statement",
        id: "g91imf1x24",
        attributes: {
          label: "What's the big idea?<br /><br />Well we're glad you asked. Here's how it works...",
          buttonText: "Continue",
          quotationMarks: false,
        }
      },
      {
        name: "statement",
        id: "g91imf1023",
        attributes: {
          label: "Tell us about your ideal adventure...",
          //description: "The more you blah blah blah blah",
          buttonText: "Continue",
          quotationMarks: false,
          attachment: {
            type: "image",
            url:
              aboutYou
          }
        }
      },
      {
        name: "statement",
        id: "g91imf1024",
        attributes: {
          label: "Our algorithm builds you a handful of bespoke trips",
          description: "Including where to stay, what to do and how to get there.<br/><br/>In future you'll be able to customise and book trips too.",
          buttonText: "Continue",
          quotationMarks: false,
          attachment: {
            type: "image",
            url:
              fanSpreadScreen
          }
        }
      },
      /*
      {
        name: "statement",
        id: "gw1imf1023",
        attributes: {
          label: "Simple eh? Now let's get started...",
          //description: "The more you blah blah blah blah",
          buttonText: "Plan my trip",
          quotationMarks: false,
        }
      },
      {
        name: "dates-custom-block",
        id: "travelDates",
        attributes: {
          label: "When would you like to depart and return?",
          description: "Flexible? Just leave this blank",
          required: false,
          verticalAlign: true,
          start: 0,
          end: 6
        }
      },
      */ 
      {
        name: "multiple-choice",
        id: "activityChoices",
        attributes: {
          required: true,
          multiple: true,
          verticalAlign: false,
          label: "Simple eh? Now let's get started...<br/><br/>What activites do you enjoy?",
          choices: categories.map((category) => {
            return {
              label: category.name,
              value: category.slug
            }
          }),
        }
      },
      {
        name: "location-custom-block",
        id: "travellingFrom",
        attributes: {
          label: "Where are you travelling from?",
          description: "Knowing where you are based allows us take your journey into account.",
          required: true,
          start: 0,
          end: 6,
        }
      },
      {
        name: "multiple-choice",
        id: "transportModes",
        attributes: {
          required: false,
          multiple: true,
          verticalAlign: false,
          label: "How do you like to get around?",
          choices: [
            {
              label: 'Car',
              value: 'driving'
            },
            {
              label: 'Train and bus',
              value: 'transit'
            }
          ],
        }
      },
      /*
      {
        name: "email",
        id: "email",
        attributes: {
          required: false,
          label: "Thanks {{field:kdsfkdg}}, please insert your email!"
        }
      },
      */
    ]



  const navigate = useNavigate();
	const handleFormSubmission = React.useCallback((answers) => {

    var locationLatLong = answers.travellingFrom.value.locationLatLong
    var locationSearchInputText = answers.travellingFrom.value.locationSearchInputText
    var cats = answers.activityChoices.value
  
    var navUrl = `/trip-plan-proto?lat=${locationLatLong.lat}&long=${locationLatLong.long}&lst=${encodeURIComponent(locationSearchInputText)}&cats=${cats.toString()}`
    
    if('travelDates' in answers){
      if(answers.travelDates.isValid && answers.travelDates.isAnswered){
        navUrl = navUrl.concat(`&start=${moment(answers.travelDates.value.startDate).format('YYYY-MM-DD')}&end=${moment(answers.travelDates.value.endDate).format('YYYY-MM-DD')}`);
      }
    }

    if('transportModes'in answers){
      if(answers.transportModes.isValid && answers.transportModes.isAnswered && answers.transportModes.value.length > 0){
        navUrl = navUrl.concat(`&transport=${answers.transportModes.value.toString()}`);
      }
    }

    
    navigate(navUrl)
      
		/*
			history.push({
				pathname: '/search',
				search: '?lat=' + locationLatLong.lat + '&long=' + locationLatLong.long + '&lst=' + encodeURIComponent(locationSearchInputText),
				state: { detail: 'some_value' }
			});
		*/				
		// history.push('/search?lat=' + locationLatLong.lat + '&long=' + locationLatLong.long + '&lst=' + encodeURIComponent(locationSearchInputText))
	}, [navigate]);

  // On page load fetches categories
  React.useEffect(() => {
    fetchCategories()
  }, [])

  return (
    <div className='trip-form' style={{ width: "100%", height: "100vh" }}>
      {categories &&
        <Form
          formId="1"
          formObj={{
            blocks: blocks,
            settings: {
              animationDirection: "vertical",
              disableWheelSwiping: true,
              disableNavigationArrows: false,
              disableProgressBar: false
            },
            theme: {
              font: "Roboto",
              buttonsBgColor: "#ff7001",
              logo: {
                src: ""
              },
              questionsColor: "#000",
              answersColor: "#0aa7c2",
              buttonsFontColor: "#fff",
              buttonsBorderRadius: 25,
              errorsFontColor: "#fff",
              errorsBgColor: "#f00",
              progressBarFillColor: "#000",
              progressBarBgColor: "#ccc"
            }
          }}
          onSubmit={(data, { completeForm, setIsSubmitting }) => {
            setTimeout(() => {
              setIsSubmitting(false);
              completeForm();
            }, 500);
            console.log(data)
            handleFormSubmission(data.answers, "Thingy text")



          }}
        />
      }
    </div>
  );
};

