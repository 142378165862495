import React from 'react';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import DirectionsTransitIcon from '@mui/icons-material/DirectionsTransit';
import './journey-times-tp-option.css';

import {ReactComponent as CarIcon} from '../../../assets/icons/car-front.svg';
import {ReactComponent as BikeIcon} from '../../../assets/icons/cycling.svg';
import {ReactComponent as WalkIcon} from '../../../assets/icons/walk.svg';
import {ReactComponent as TransitIcon} from '../../../assets/icons/train.svg';

export const JourneyTimesTpOption = (props) => {
    return (
      <div className='journey-data-tp-option'>
        <div className="journey-data journey-data-formatted">
          {props.travelTime.transit  &&  props.transitRouteUrl ?
            <a href={props.transitRouteUrl} target="_blank"><TransitIcon className="tranport-mode-icon"/>{props.travelTime.transit.human_readable}</a>
            :props.travelTime.transit  &&
            <div href={props.transitRouteUrl} target="_blank"><TransitIcon className="tranport-mode-icon"/>{props.travelTime.transit.human_readable}</div>
          
          }
          {props.travelTime.driving &&  
            <a href={props.drivingRouteUrl} target="_blank"><CarIcon className="tranport-mode-icon"/>{props.travelTime.driving.human_readable}</a>
          }
        </div>
      </div>

    )
}