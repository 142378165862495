import * as React from 'react';
import './generic-form-modal.css';

import {
  BrowserRouter as Router,
  Link,
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";
import ReactGA from "react-ga4";
import newsletterImage from '../../../assets/images/email-popup/roberto-nickson-iWzpYUSxIu4-unsplash_reduced.jpg';
import closeIcon from '../../../assets/icons/popup-close-white.svg';
import emailjs from 'emailjs-com';

// a basic form
// some taken from official docs, some from here https://dev.to/gedalyakrycer/create-an-email-list-with-react-mailchimp-965#step6
export const TripEnquryForm = ({ closeModal, message, onValidated, selectedAccomm, tripArea }) => {
  const [email, setEmail] = React.useState('');
  const [name, setName] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [status, setStatus] = React.useState();
  let location = useLocation();

  const sendEnquiryEmailToWildr = (customerEmail, name, phone) => {
  console.log("ASDASDASDASDAS")
  console.log('selectedAccommp in TripEnquryForm', selectedAccomm)
  console.log('props')
  console.log('location', location)
  var url = `https://wildr.co.uk${location.pathname}${location.search}`
  console.log('url', url)
  
  console.log('tripArea', tripArea)


    var templateParams = {
      selected_accomm_name: selectedAccomm.title,
      selected_accomm_id: selectedAccomm.id,
      url: `https://wildr.co.uk${location.pathname}${location.search}`,
      trip_area_title: tripArea.title,
      trip_area_id: tripArea.id,
      name: name,
      name: phone,
      enquiry_email: customerEmail,
      message: 'Check this out!'
    };

    var service_id = 'service_wildr_web_k12j39'
    var template_id = 'template_8ymjrd3'
    var PUBLIC_KEY = 'Uc9vy9eBv-8FiGJtN'

    emailjs.send(service_id, template_id, templateParams, PUBLIC_KEY)

      .then(function (response) {
        console.log('SUCCESS!', response.status, response.text);
        setStatus("success")
      }, function (error) {
        console.log('FAILED...', error);
        setStatus("error")
      });
  }


  const submit = () => {
    if (status != "sending" && status != "success") {
      if (email) {
        if (email.indexOf("@") > -1) {
          setStatus("sending")
          sendEnquiryEmailToWildr(email, name, phone)
        }
      }
    }
  }

  React.useEffect(() => {
    if (status === "success") {
      clearFields()
      ReactGA.event("mailsignup-success-popup", {
      });
    };
  }, [status])


  const clearFields = () => {
    
    
    setTimeout(() => {
      closeModal()
      setEmail('')
      setPhone('')
      setName('')
      setStatus(null)
    }, 2500)
  }

  const handleEmailChange = event => {
    setEmail(event.target.value);
  };
  const handleNameChange = event => {
    setName(event.target.value);
  };

  const handlePhoneChange = event => {
    setPhone(event.target.value);
  };

  return (
    <div className='enquiry-form'
      style={{
      }}
    >
      {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
      {status === "error" && <div style={{ color: "red" }}>There was an error. Please contact us at info@wild.co.uk.</div>}
      {status === "success" && <div style={{ color: "orange" }}>Enquiry sent! We'll be in touch soon. </div>}
      <br />
      <input
        label="Name"
        onChange={handleNameChange}
        // ref={node => (email = node)} breaks with Error: "email" is read-only
        type="text"
        value={name}
        placeholder="Name"
      />
      <input
        label="Email"
        onChange={handleEmailChange}
        // ref={node => (email = node)} breaks with Error: "email" is read-only
        type="text"
        value={email}
        placeholder="Email"
      />
      <input
        label="Phone"
        onChange={handlePhoneChange}
        // ref={node => (email = node)} breaks with Error: "email" is read-only
        type="text"
        value={phone}
        placeholder="Phone"
      />
      <br />
      <button className="orange-filled-button" style={{ cursor: "pointer" }} onClick={submit}>
        Submit
      </button>
      <br />
      {/* 
      <div className='nope' onClick={closeModal}>
        No thanks
      </div>
      */}
    </div>
  );
};
