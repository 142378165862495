import React from 'react';

export function FiltersBar() {
    return(
    <div className="filters-bar">
        <div className="filters-box">
            <div className="filters">Outdoors</div>
            <div className="filters">Food    & drink</div>
            <div className="filters">Rainy day</div>
            <div className="filters">Events</div>
        </div>
    </div>)
}