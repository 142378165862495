import React from 'react';

export const Crosshair = ({size, color}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square"/>
      <path d="M12 1V5" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square"/>
      <path d="M23 12H19" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square"/>
      <path d="M12 23V19" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square"/>
      <path d="M1 12H5" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square"/>
      <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="square"/>
      <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" fill={color}/>
    </svg>
  )
}
