import React from 'react';
import './trip-item.css';

import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
import axios from 'axios';

export const TripItem = (props) => {
  const [googlePlaceDetails, setGooglePlaceDetails] = React.useState(null);

  let location = useLocation();

  // React.useEffect on page load
  React.useEffect(() => {
    if (props.activity.source && props.activity.source.slug === 'google-places'){
      getGooglePlaceDetailsWildr()
    }
  }, [])


  const getGooglePlaceDetailsWildr = () => {
      let url = `${process.env.REACT_APP_WILDR_PUBLIC_API}/google_place_details?place_id=${props.activity.partner_id}&fields=name,photo`
      axios.get(url).then((response) => {
        setGooglePlaceDetails(response.data)
      }).catch(error => {
          console.log("error", error)
      });
  }

  return (
    <>
    
    {props.activity &&
        <Link to={`/activities/` + props.activity.id} key={props.activity.id.toString()} 
          className={`trip-card-wrapper half-width${!props.activity.feature_in_trips  && !props.showNonFeaturedActivities ? " hidden": ""}`} 
          data-activity-id={props.activity.id} onClick={props.handleAcivityViewed} 
          state={{ background: location ,name: props.activity.name, travelTime: props.activtyTravelTime, travelTimeLabel: 'Travel times from your accommodation' }}
        >
          <div className="image-container">
            
            {props.activity.source == null || props.activity.source.slug != 'google-places' ?
            // This as a simple && without the ?..: fails - messes up the or logic above somethow. 
              <>
              
                <img src={props.activity.images.length > 0 ? `${props.activity.images[0].url}?tr=w-${props.imageWidthPx}` : props.activity.external_images.length > 0 ? `${process.env.REACT_APP_EXTERNAL_IMAGE_PROXY_URL}${props.activity.external_images[0].url}?tr=w-${props.imageWidthPx}` : "http://image.com/jejee.jpg"} />
                <div className="overlay-text">
                  {/*props.activity.pricing.is_free && <div>Free!</div>*/}
                  {
                    <div>
                      {props.activity.category.slug != "beyonk-temp" && props.activity.category.icon_url && <span><img className='trip-card-category-icon' src={props.activity.category.icon_url}></img></span>}
                      {props.activity.pricing.minimum_pounds_rounded_up && <span>From £{props.activity.pricing.minimum_pounds_rounded_up}</span>}
                    </div>
                  }
                  <div>{props.activity.name}</div>
                  {/* 
                  {props.activtyTravelTime.activity_id &&
                    <div className="overlay-travel-times journey-section left-hand">
                      <JourneyTimesTpActivity travelTime={props.activtyTravelTime} showDriving={true} showCycling={false} />
                    </div>
                  }
                  */}

                </div>
              </>:
              ""
            }

            {props.activity.source && props.activity.source.slug === 'google-places' && googlePlaceDetails &&
              <>

                {googlePlaceDetails.photos.length > 0 &&
                  <img src={`https://maps.googleapis.com/maps/api/place/photo?maxwidth=${props.imageWidthPx}&photoreference=${googlePlaceDetails.photos[0].photo_reference}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`} />
                }
                <div className="overlay-text">
                  {/*props.activity.pricing.is_free && <div>Free!</div>*/}
                  {
                    <div>
                      {props.activity.category.slug != "beyonk-temp" && props.activity.category.icon_url && <span><img className='trip-card-category-icon' src={props.activity.category.icon_url}></img></span>}
                      {props.activity.pricing.minimum_pounds_rounded_up && <span>From £{props.activity.pricing.minimum_pounds_rounded_up}</span>}
                    </div>
                  }
                  <div>{googlePlaceDetails.name}</div>
                  {/* 
                    {props.activtyTravelTime &&
                      <div className="overlay-travel-times journey-section left-hand">
                        <JourneyTimesTpActivity travelTime={props.activtyTravelTime} showDriving={true} showCycling={false} />
                      </div>
                    }
                  */}
                </div>
              </>
            }


          </div>
        </Link>
    }
    </>
  )
}