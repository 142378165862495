import * as React from 'react';
import {
    Link
} from "react-router-dom";
import './area-guide-grid.css';


export const AreaGuideGrid = (props) => {
    return (
        <div className="container">
            <div>
                <h2>Area Guides</h2>
                <div className="h2-subtitle">See what's happening in your patch</div>
                <div className="guide-grid">
                    {props.areaGuides.map((item, index) => {
                        if (item.featured === true) {
                            return (
                                <div key={item.id}>
                                    <Link className="guide-card" to={`/area-guides/${item.id}/${item.slug}`} state={{ titleFromLink: item.title }} >
                                        <div className="guide-card-hero"><img
                                            src={item.images.length ? item.images[0].url + "?tr=w-350" : "images/activity-category-images/hill-walking-iStock-1049861528.jpg?tr=w-350"}></img>
                                        </div>
                                        <h3>{item.title}</h3>
                                        <p>{item.tagline}</p>
                                    </Link>
                                </div>
                            )
                        }
                    })}
                </div>
            </div>
        </div>
    )
}
