import React from 'react';
import InstagramLogo from '../../../assets/icons/socials/instagram.png';
import FacebookLogo from '../../../assets/icons/socials/facebook.png';
import TikTokLogo from '../../../assets/icons/socials/tiktok.png';
import YouTubeLogo from '../../../assets/icons/socials/youtube48.png';
import TwitterLogo from '../../../assets/icons/socials/x.png';
import DefaultLogo from '../../../assets/icons/socials/generic.png';
import './social-links.css';

export const SocialLinks = ({ socialLinks }) => {
    return(
        <div className="social-links">
        {socialLinks && Object.keys(socialLinks).map((platform) => {
            let logo;
            switch (platform) {
                case 'facebook':
                    logo = <img src={FacebookLogo} alt="Facebook" />;
                    break;
                case 'instagram':
                    logo = <img src={InstagramLogo} alt="Instagram" />;
                    break;
                case 'tiktok':
                    logo = <img src={TikTokLogo} alt="TikTok" />;
                    break;
                case 'youtube':
                    logo = <img src={YouTubeLogo} alt="YouTube" />;
                    break;
                case 'twitter':
                    logo = <img src={TwitterLogo} alt="Twitter" />;
                    break;
                default:
                    logo = <img src={DefaultLogo} alt="Other" />;
            }

            return (
                <a key={platform} href={socialLinks[platform][0]} target="_blank" rel="noreferrer noopener">
                    {logo}
                </a>
            );
        })}
    </div>
    )

}
