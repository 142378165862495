import * as React from 'react';
import './contact.css';

import contactUsImage from '../../assets/images/contact-us/joseph-barrientos-UknkN5txy_4-unsplash-crop.jpg';


export const Contact = (props) => {
    return (
        
        <div className="contact-us">
        <div className="container split">

            <div>
                <h2 className="right-side-text">Contact Us</h2>
                <p className="right-side-text">
                    We're friendly :)
                </p>
                <p className="right-side-text">
                    <a href="mailto:info@wildr.co.uk">info@wildr.co.uk</a>
                </p>
                <p className="right-side-text">
                    <a href="tel:+442080501527">+44 (0)20 8050 1527</a>
                </p>
            </div>
            <div>
                <img src={contactUsImage+"?tr=w-850"} alt=""></img>
            </div>
        </div>
    </div>






    )
}

