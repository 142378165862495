import * as React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

export default function Preload({ showPreloader }) {
  return (
    <Backdrop
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={showPreloader}
    >
      <CircularProgress color='primary'/>
    </Backdrop>
  )
}
