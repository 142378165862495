import React from 'react';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import DirectionsTransitIcon from '@mui/icons-material/DirectionsTransit';
import './journey-times.css';

import {ReactComponent as CarIcon} from '../../../assets/icons/car-front.svg';
import {ReactComponent as BikeIcon} from '../../../assets/icons/cycling.svg';
import {ReactComponent as WalkIcon} from '../../../assets/icons/walk.svg';
import {ReactComponent as TransitIcon} from '../../../assets/icons/train.svg';

export const JourneyTimes = (props) => {
    return (
      
      <div className='journey-data-search'>
        <hr></hr>
        {props.travelTimeLabel && <div className='journey-data-label'>{props.travelTimeLabel}</div>}
        <div className="journey-data journey-data-formatted">
          {/* <div className=''>{props.item.distance.km}km away</div>*/}

          <div><WalkIcon className="tranport-mode-icon"/>{'walking' in props.traveTime && props.traveTime.walking ? props.traveTime.walking.human_readable : "-"}</div>
          <div><BikeIcon className="tranport-mode-icon"/>{'cycling' in props.traveTime && props.traveTime.cycling ? props.traveTime.cycling.human_readable : "-"}</div>
          <div><CarIcon className="tranport-mode-icon"/>{'driving' in props.traveTime && props.traveTime.driving ? props.traveTime.driving.human_readable : "-"}</div>
          <div><TransitIcon className="tranport-mode-icon"/>{'transit' in props.traveTime && props.traveTime.transit ? props.traveTime.transit.human_readable : "-"}</div>
        </div>
        <div className="journey-data journey-data-minutes">
        
        {/*
          <div>
            <ArrowRightAltIcon className="tranport-mode-icon"/>
             <div>{props.item.distance.km} 
              <span className="unit-text-small">km</span>
            </div>
          </div>            
        */}
          <div><WalkIcon className="tranport-mode-icon"/><div>{'walking' in props.traveTime && props.traveTime.walking ? <span>{Math.round(props.traveTime.walking.total_seconds / 60)}<span className="unit-text-small">min</span></span>  : "-"}</div></div>
          <div><BikeIcon className="tranport-mode-icon"/><div>{'cycling' in props.traveTime && props.traveTime.cycling ? <span>{Math.round(props.traveTime.cycling.total_seconds / 60)}<span className="unit-text-small">min</span></span>  : "-"}</div></div>
          <div>
            <CarIcon className="tranport-mode-icon"/>
            <div>
              {'driving' in props.traveTime && props.traveTime.driving ? <span> {Math.round(props.traveTime.driving.total_seconds / 60)}<span className="unit-text-small">min</span></span>   : "-"}
            </div>
          </div>
          <div><TransitIcon className="tranport-mode-icon"/><div>{'transit' in props.traveTime && props.traveTime.transit ? <span>{Math.round(props.traveTime.transit.total_seconds / 60)}<span className="unit-text-small">min</span></span>  : "-"}</div></div>

        </div>
      </div>

    )
}