import * as React from 'react';
import './tp-viewer-poc.css';
import { Widget } from '@typeform/embed-react'
import { PopupButton } from '@typeform/embed-react'
import axios from "axios";
import {
    BrowserRouter as Router,
    Link,
    useLocation,
    useNavigate,
    useParams,
    useRouteMatch
} from "react-router-dom";
import { unstable_batchedUpdates } from 'react-dom';
import { JourneyTimesTpActivity } from "../../components/journey-times-tp-activty/journey-times-tp-activity"
import { TpMap } from "../../components/map/tp-map/tp-map"
import { JourneyTimesTpManualTitle } from "../../components/journey-times-tp-manual-title/journey-times-tp-title"
import HotelRoundedIcon from '@mui/icons-material/HotelRounded';
export const TpViewerPoc = (props) => {
    const { customerPortalUuid } = useParams();
    const [customer, setCustomer] = React.useState();
    const [tripPlans, setTripPlans] = React.useState();
    const [initialCustomerFetchComplete, setInitialCustomerFetchComplete] = React.useState(false);
    const [activities, setActivites] = React.useState([]);
    const [isLoadedInitalJourneyTimes, setIsLoadedInitalJourneyTimes] = React.useState(false);

    const triptTransportModes = ['walking', 'transit']

    let location = useLocation();
    // On page load fetch customer
    React.useEffect(() => {
        fetchcustomer()
    }, [])

    const fetchcustomer = () => {

        let url = process.env.REACT_APP_WILDR_PUBLIC_API + "/manual_trip_plan_customer_detail/" + customerPortalUuid
        axios.get(url).then((response) => {

            unstable_batchedUpdates(() => {
                setCustomer(response.data) // TODO exclude trip plans as store in sep state!
                setTripPlans(response.data.trip_plans)
            })
            console.log(response.data)
        }).catch(error => {
            console.log("error", error)
        });
    }


    const mergeTravelTimes = (originalActivities, additionalActivityTravelTimes) => {
        // We do a seperate query to fetch more travel modes as we use two different APIs
        const arrr = []
        originalActivities.forEach(item => {
            arrr.push(item)

        });
        additionalActivityTravelTimes.forEach(item => {
            arrr.find(x => x.id === item.id)['travel_time'] = { ...arrr.find(x => x.id === item.id)['travel_time'], ...item['travel_time'] }
        });
        return (arrr)
    }

    const fetchTripTravelTimes = (tripPlan, indexCounter, transport) => {
        // gather trip plan activities ids into a list
        let activitiesIds = []
        tripPlan['activities'].forEach((tripPlanActivity) => {
            activitiesIds.push(tripPlanActivity['id'])
        })

        // convet list to string
        let activitiesIdsString = activitiesIds.join(',')
        var urly = process.env.REACT_APP_WILDR_PUBLIC_API + "/search?lat=" + tripPlan.accommodation.location.latitude + "&long=" + tripPlan.accommodation.location.longitude
            + "&transport=" + transport + "&fields=id,travel_time" + "&id=" + activitiesIds.join(',')


        fetch(urly)
            .then(res => res.json())
            .then(
                (result) => {

                    const merge = mergeTravelTimes(tripPlans[indexCounter].activities, result.results) // TODO this may be doing some nasty stuff to state :/

                    // https://stackoverflow.com/questions/29537299/how-can-i-update-state-item1-in-state-using-setstate
                    // 1. Make a shallow copy of the items
                    let items = [...tripPlans];
                    // 2. Make a shallow copy of the item you want to mutate
                    let item = { ...items[indexCounter] };
                    // 3. Replace the property you're intested in
                    item.activities = merge;
                    // 4. Put it back into our array. N.B. we *are* mutating the array here, 
                    //    but that's why we made a copy first
                    items[indexCounter] = item;
                    // 5. Set the state to our new copy

                    setTripPlans(items);

                    // SO we're getting driving, now add more times in! a) just do it immediately. B some other clever way

                },
                (error) => {
                    // setLoadActivitiesError(error);
                }
            )

    }


    // Get activity travel times after trip fetch
    React.useEffect(() => {

        if (customer) {
            fetchTripsPlanActivitiesTravelTimes()
        }
    }, [customer])

    // We use manual delays instead of nice async as I'm in a rush and this should work ok 
    const fetchTripsPlanActivitiesTravelTimes = async () => {


        // iterate over trip plans
        customer.trip_plans.forEach((tripPlan, index) => {
            // for each one, fetch activities and then additional travel times. Push into the tripPlans object state.. 
            fetchTripTravelTimes(tripPlan, index, 'driving')
        })

        await delay(500); // TODO get rid of this crap. Probs doesn't even help anything
        customer.trip_plans.forEach((tripPlan, index) => {
            // Delay between? 
            fetchTripTravelTimes(tripPlan, index, 'transit')
            fetchTripTravelTimes(tripPlan, index, 'cycling')
            fetchTripTravelTimes(tripPlan, index, 'walking')

        })

    };


    // Hacky way to delay stuff rather than implement proper async and logic as in a rush. 
    const delay = ms => new Promise(res => setTimeout(res, ms));

    return (

        <section className="trip-plan">
            <div className="container">
                <h2>Hi!</h2>
                <div className="h2-subtitle">We've created some personalised trip ideas for you</div>

                {tripPlans != null && tripPlans.map(function (object, i) {
                    return (

                        <div className="trip-option" key={i}>
                            <div className="option-counter">Option {i + 1} / {tripPlans.length}</div>

                    
                            <div className="trip-title-block">
                                <div>
                                    <h3>{object.title}</h3>

                                    { /*<p>Mon 23rd - Fri 27th May 2022</p> */}
                                </div>
                                { /* <p>~£720</p> */}

                                <JourneyTimesTpManualTitle transit_formatted={object.journey_time_transit_formatted} driving_formatted={object.journey_time_driving_formatted}
                                    transit_route_url={object.journey_transit_url} driving_route_url={object.journey_driving_url} />
                            </div>

                            <div className="trip-components">
                                <div className="trip-card-wrapper">
                                    <div className="image-container">
                                        <div className="destination-card-text">

                                            <div>{object.destination.title}</div>
                                        </div>
                                        {/*<img src ="https://media.istockphoto.com/photos/clovelly-picture-id468048704?k=20&m=468048704&s=612x612&w=0&h=qtJCr6thJmUZPwuWxZ67FxS-PcBfMTmudgDOGuOxd5U="></img>  */}
                                        <img src={object.destination.image}></img>
                                    </div>
                                </div>

                                <a className="trip-card-wrapper" href={object.accommodation.external_url} target="_blank">
                                    <div className="image-container">
                                        {/* <img src ="https://media.istockphoto.com/photos/clovelly-picture-id468048704?k=20&m=468048704&s=612x612&w=0&h=qtJCr6thJmUZPwuWxZ67FxS-PcBfMTmudgDOGuOxd5U="></img>*/}
                                        <img src={object.accommodation.image}></img>
                                        <div className="overlay-text">
                                            <div><span><HotelRoundedIcon /></span>{object.accommodation.price_per_night && <span>From £{parseFloat(object.accommodation.price_per_night).toFixed(0)}/night</span>}</div>
                                            <div>{object.accommodation.title}</div>
                                        </div>
                                    </div>
                                </a>

                                {object.activities.map(function (activity, i) {
                                    return (
                                        <Link to={`/activities/` + activity.id} key={i} className="trip-card-wrapper half-width" data-activity-id={activity.id} onClick={null} state={{ background: location }}>

                                                <div className="image-container">
                                                    <img src={activity.images.length > 0 ? activity.images[0].url : activity.external_images.length > 0 ? activity.external_images[0].url : "http://image.com/jejee.jpg"} />
                                                    <div className="overlay-text">

                                                        {/*activity.pricing.is_free && <div>Free!</div>*/}





                                                        {
                                                            <div>
                                                                {activity.category.slug != "beyonk-temp" && activity.category.icon_url && <span><img className='trip-card-category-icon' src={activity.category.icon_url}></img></span>}
                                                                {activity.pricing.minimum_pounds_rounded_up && <span>From £{activity.pricing.minimum_pounds_rounded_up}</span>}
                                                            </div>
                                                        }




                                                        <div>{activity.name}</div>
                                                        {activity?.travel_time &&
                                                            <div className="overlay-travel-times journey-section left-hand">
                                                                <JourneyTimesTpActivity item={activity} showDriving={customer.activity_transport_mode_driving} showCycling={customer.activity_transport_mode_cycling} />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                         
                                        </Link>
                                    )
                                })}

                                <div className="trip-card-wrapper" href={object.accommodation.external_url} target="_blank">
                                    <div className="image-container">
                                        <TpMap mapFocus={{lat: object.accommodation.location.latitude, long: object.accommodation.location.longitude}} 
                                            mapHomeCoords={{lat: object.accommodation.location.latitude, long: object.accommodation.location.longitude}}  
                                            activities={object.activities}
                                        />
                                    </div>
                                </div>


                            </div>
                        </div>
                    )
                })}

            
 


            </div>
        </section>
    )
}

