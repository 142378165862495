import React, {useState, useEffect} from 'react';

// potentially the below method of using a selectActivity state is too simple and will lead to other issues without refs/
// some potential other approaches can be found here: https://stackoverflow.com/questions/21285923/reactjs-two-components-communicating
// Use this for now as it seemingly works?

export const ResultsList = (props) => {
  return (<div>
    {props.results.map((object, i) =>
          <div key={object.id} data-activity-id={object.id} onClick={props.clickHandler} style={{ backgroundColor: props.selectedActivityId != null && props.selectedActivityId === object.id? 'yellow': 'white'}} >
            list card result: id: {object.id} name: {object.name}
          </div>
    )}
  </div>)
}

export const MapArea = (props) => {
  return (<div>
    {props.results.map((object, i) =>
          <div key={object.id} data-activity-id={object.id} onClick={props.clickHandler} style={{ backgroundColor: props.selectedActivityId != null && props.selectedActivityId === object.id? 'yellow': 'white'}} >
            map marker result: id: {object.id} name: {object.name}
          </div>
    )}
  </div>)
}

export const TestApp = () => {

  const [selectedActivityId, setSelectedActivityId] = React.useState()

  const results = [{'id': 1, 'name': "no1" }, {'id': 2, 'name': "no2" }, {'id': 3, 'name': "no3" }]

  const activitySelectHandler = (e) => {
      console.log("genericClickHandler Set activity result click")
      const aid = parseInt(e.currentTarget.getAttribute("data-activity-id"));
      setSelectedActivityId(aid)
    }

  React.useEffect( () => {console.log("Selected activity changed - do some stuff")},  [selectedActivityId])

  return (
      <div>
        <ResultsList results={results} clickHandler={activitySelectHandler} selectedActivityId={selectedActivityId}/>
        <MapArea results={results} clickHandler={activitySelectHandler} selectedActivityId={selectedActivityId}/>
      </div>
)
}

export default TestApp;