import * as React from 'react';
import * as Ariakit from "@ariakit/react";
import "./activity-modal.css";

import './header-bar.css';
import { HeaderBar } from './header-bar'
import { Footer } from './components/footer/footer';

import {
  BrowserRouter as Router,
  Link,
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";
import { ActivityDetailPage } from './activity-detail-page'

export const ActivityModal = (props) => {
  let navigate = useNavigate();
  const [open, setOpen] = React.useState(true);


  function onDismiss() {
    document.title = "Adventure trip planner - Wildr"
    navigate(-1);
  }

  const Component = props.component;

  return (
    <div className="BOOM">

      <Ariakit.Dialog
        open={open}
        onClose={() => setOpen(false)}
        className="full-screen-modal"
        preventBodyScroll={false}
      >
        <div className="modal-content">
          <div className="content">
            <HeaderBar transparentMode={false} />
            <Component handleCloseModal={onDismiss} />
            {/* <GuidesList className="area-guides"/> */}

          </div>
          <Footer className="area-guides" />
        </div>
      </Ariakit.Dialog>
    </div>
  );
}