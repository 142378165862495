import React from 'react';
import './menu.css';
import {
  BrowserRouter as Router,
  Link,
  useLocation,
  useNavigate,
  useParams 
} from "react-router-dom";
import { signOut } from "supertokens-auth-react/recipe/passwordless";
import Session, { SessionAuth } from "supertokens-auth-react/recipe/session";
// import Session, { SessionAuth } from "supertokens-auth-react/recipe/session";

import { Button } from '@mui/material';
import Divider from '@mui/material/Divider';

export const Menu = (props) => {
  const [transparentNavbar, setTransparentNavbar] = React.useState(props.transparentMode)
  const [showMenu, setShowMenu] = React.useState(false);


  function displayMenu() {
    setShowMenu(value => !value)
  }

  const handleLogout = async () => {
    try {
      await signOut();
      window.location.href = "/auth";
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  //       <div className='menu-icon'><a href="javascript:void(0);" className={props.transparentNavbar ? "pill-button orange" : "menu-icon pill-button simple"}>Menu</a></div>


  return (
    <React.Fragment>
      <div className='menu'>
        <div className={showMenu ? 'ham-menu-icon active' : 'ham-menu-icon'} onClick={displayMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className={showMenu ? 'righthand-menu responsive' : 'righthand-menu static'}>
          <div className="menu-items">

            {/*!props.isLoggedIn &&
            <>
              <Link to={'/auth'} className="menu-text-item" onClick={(e) => setShowMenu(false)} title="Sign up">Sign up</Link>
              <Link to={'/auth'} className="menu-text-item" onClick={(e) => setShowMenu(false)} title="Login">Login</Link>
           
              <hr></hr>
              </>
  */}
            


            <Link to={'/'} className={props.transparentNavbar ? "menu-text-item dark-background" : "menu-text-item"} onClick={(e) => setShowMenu(false)}>Home</Link>
            
            
            <Link to={'/search'} className="menu-text-item" onClick={(e) => setShowMenu(false)} title="Explore">Explore</Link>
            {/* <Link to={'/trip-plan'} className="menu-text-item" onClick={(e) => setShowMenu(false)} title="Trip planner">Trip planner</Link> */}
            <Link to={'/trip-plan'} className="menu-text-item" onClick={(e) => setShowMenu(false)} title="Trip Planner">Trip Planner</Link>
            {/* <Link to={'/about-us'} className="menu-text-item" onClick={(e) => setShowMenu(false)} title="Our story">Our story</Link> */}
            <Link to={'/contact'} className="menu-text-item" onClick={(e) => setShowMenu(false)} title="Contact">Contact</Link>
            {/* <Link to={'/contribute'} className={showMenu ? "pill-button" : props.transparentNavbar ? "pill-button dark-background" : "pill-button"}  onClick={(e) => setShowMenu(false)} title="Contribute">Contribute</Link> */}
            { /* <Link to={'/about-us'} className='pill-button orange'  onClick={(e) => setShowMenu(false)} title="Contribute"><div>Contribute</div></Link> */}

            {props.isLoggedIn &&
              <>
                
                <hr></hr>
                <Link to={'/auth'} 
                  className="menu-text-item"
                  onClick={(e) => {
                    handleLogout()
                    }
                  } 
                  title="Logout">Logout</Link>
              </>
            }

          </div>
        </div>
      </div>
    </React.Fragment>
  )
}