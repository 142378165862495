import * as React from 'react';
import './generic-form-modal.css';

import {
  BrowserRouter as Router,
  Link,
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";

import * as Ariakit from "@ariakit/react";

import closeIcon from '../../../assets/icons/popup-close-white.svg';
import { ReactComponent as Logo } from '../../../assets/icons/popup-close-white.svg';

export const GenericFormModal = (props) => {
  const url = "https://wildr.us20.list-manage.com/subscribe/post?u=5744114d84578e062b17229ee&amp;id=cb4ad3dc7b";

  let navigate = useNavigate();
  let { id } = React.useRef(null);

  return (
    <div className=''>

      <Ariakit.Dialog className="newsletter-dialog" open={props.showModal} onClose={props.handleCloseTripEnquiryModal}>  
    <div className='trip-enquiry-popup'>
      {/* <Dialog className='trip-enquiry-popup' isOpen={props.showModal} onDismiss={props.handleCloseTripEnquiryModal}> */}
        <div className='trip-enquiry-popup-container'>
        <div className="form-popup-close-icon" onClick={props.handleCloseTripEnquiryModal}>
                <Logo stroke="white"/>
            </div>
          <div className="split">
            
            <div className="enquiry-image one">
            <img src={`${props.heroImage}?tr=h-600`}></img>
            </div>
            <div className="enquiry-main two">
              <div className="enquiry-info">
                <h1>{props.title}</h1>
                <p>{props.subtitle}</p>
                
                {/* We pass in either a trip enquiry form or a feedback request form */}
                {props.formComponent()}
                {props.footnote && 
                  <div className="footnote">{props.footnote}</div>
                }
                
              </div>
            </div>
          </div>
        </div>
        </div>
      </Ariakit.Dialog>
    </div>
  );
}
