import React, { useState, useEffect, useRef } from 'react';

import { ActivitiesSearchPage } from './pages/activities-search-page'
import { AreaGuideDetailPage } from './pages/area-guide-detail'
import { AboutUs } from './pages/about-us/about-us'
import { LandingPageTpBased } from './pages/components/landing-page/landing-page'
import { LandingPageActivityBased } from './pages/components/landing-page-activity-based/landing-page-activity-based'
import { TripPlanner } from './pages/trip-planner/trip-planner'
import { TpViewerPoc } from './pages/trip-planner/tp-viewer-poc/tp-viewer-poc'
import { TpSearchProto } from './pages/trip-planner/tp-search-proto/tp-search-proto'
import { Contact } from './pages/contact/contact'
import { PrivacyPolicy } from './pages/privacy-policy'
import { ActivityModal } from './pages/activity-modal'
import { ArrivalModal } from './pages/components/arrival-modal/arrival-modal'
import { BetaSignupModal } from './pages/components/beta-signup-modal/beta-signup-modal'
import { ActivityDetailPage } from './pages/activity-detail-page'
import { AccommDetailPage } from './pages/accomm-detail/accomm-detail-page'
import useGoogleAnalytics from './googleAnalyticsComponent'
import { NewsletterSignup } from './pages/components/newsletter-signup/newsletter-signup';
import { Footer } from './pages/components/footer/footer';
import { HeaderBar } from './pages/header-bar'
import './App.css';
import {TripForm } from './pages/components/trip-form/trip-form';
import axios from "axios";
import {
  Routes,
  Route,
  useLocation,
  Link,
} from "react-router-dom";
import Preload from './pages/components/Preload';
import { SiteContext } from './utils/contexts';
import { HeroSearch } from './pages/components/hero-search/hero-search' // We need the CSS from this, even if not used!
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import Passwordless from "supertokens-auth-react/recipe/passwordless";

import Session, { SessionAuth } from "supertokens-auth-react/recipe/session";
import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui";
import { PasswordlessPreBuiltUI } from "supertokens-auth-react/recipe/passwordless/prebuiltui";
import * as reactRouterDom from "react-router-dom";


{/* 
import { CategoyGrid } from './pages/components/category-image-grid/activity-category-grid'
import { FeaturedActivties } from './pages/components/featured-activities/featured-activities'
import { AboutUsOverview } from './pages/components/about-us-overview/about-us-overview'
import { AreaGuideGrid } from './pages/components/area-guide-grid/area-guide-grid';
import { GuidesList } from './pages/components/guides-list/guides-list';
import { HomepageLocationSearch } from './pages/components/homepage-location-search/homepage-location-search'
*/}

SuperTokens.init({
    appInfo: {
        appName: "Wildr",
        apiDomain: process.env.REACT_APP_SUPERTOKENS_API_DOMAIN, //  "https://staging.wildr.co.uk" "http://127.0.0.1:8000"
        websiteDomain: process.env.REACT_APP_SUPERTOKENS_UI_ROOT_URL, // http://localhost:3000
        apiBasePath: "/api/v1/auth",
        websiteBasePath: "/auth"
    },
    recipeList: [
      
        Passwordless.init({
            contactMethod: "EMAIL",
            style: `
            [data-supertokens~=container] {
                margin-top:126px;
            }
        `
        }),
        Session.init()
    ]
});

// A custom hook that builds on useLocation to parse the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

// Used to load google places apis
function loadScript(src, position, id) {
  if (!position) {
    return;
  }
  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

export const App = () => {

    
  let query = useQuery();


  // Used to load google places apis
  /*
  const loaded = useRef(false);
  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        'https://maps.googleapis.com/maps/api/js?key='+ process.env.REACT_APP_GOOGLE_API_KEY + '&libraries=places&callback=initMap',
        document.querySelector('head'),
        'google-maps',
      );
    }
    loaded.current = true;
  }
  */
  useGoogleAnalytics(); // We just use this to init analytics - commected out page views auto as GA enhanced measruement does this.

  // Tutorial on loading a modal or a dedicated page (used for activity detail) here: https://stackblitz.com/github/remix-run/react-router/tree/main/examples/modal?file=src%2FApp.tsx
  let location = useLocation();

  // The `backgroundLocation` state is the location that we were at when one of
  // the gallery links was clicked. If it's there, use it as the location for
  // the <Routes> so we show the gallery in the background, behind the modal.
  let state = location.state;

  let background = location.state && location.state.background;

  const [areaGuides, setAreaGuides] = useState([]);
  const [isLoadedAreaGuides, setIsLoadedAreaGuides] = useState(false);
  const [isLoadedAreaGuidesError, setIsLoadedAreaGuidesError] = useState(null);
  const [showPreloader, setShowPreloader] = useState(false);
  const [showArrivalModal, setShowArrivalModal] = useState(false);
  const [showBetaModal, setShowBetaModal] = useState(false);
  

  // Check supertokens login status
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Add this line
  let sessionContext = Session.useSessionContext();
  useEffect(() => {
    
    if (sessionContext.doesSessionExist) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [sessionContext.doesSessionExist]);




  const siteContextValues = {
    setShowPreloader: setShowPreloader
  };
 
  const handleBetaButtonClick = () => {
    setShowBetaModal(true)
  }

  const handleBetaModalCloseClick = () => {
    setShowBetaModal(false)
  }

  const fetchGuides = () => {
    setIsLoadedAreaGuides(false)
    let url = process.env.REACT_APP_WILDR_PUBLIC_API + "/area_guides/"
    axios.get(url).then((response) => {
      setAreaGuides(response.data)

      setIsLoadedAreaGuides(true)

    }).catch(error => {
      console.log("error", error)
      setIsLoadedAreaGuidesError(error)
    });
  }

  // On page load
  React.useEffect(() => {

    // fetch activities
    //fetchGuides()

    // Timer test
    if (process.env.REACT_APP_SHOW_ARRIVAL_POPUP == 'true') {
      setTimeout(() => {
        // Popup logic
        // Only show the arrival popup if enabled and the url query params don't include source=ga
        // (we want to suppress the popup for adwords referral)
          if (query.get('source')) {
            if (query.get('source') !== 'ga') {
              setShowArrivalModal(true)
            }
          }
          else {
            setShowArrivalModal(true)
          }
      }, parseInt(process.env.REACT_APP_ARRIVAL_POPUP_DELAY))
    }

  }, [])


  return (
    <>

      {showArrivalModal && <ArrivalModal />}
      {showBetaModal && <BetaSignupModal handleCloseClick={handleBetaModalCloseClick}/>}

      <SiteContext.Provider value={siteContextValues}>
        <Preload showPreloader={showPreloader}/>
      <div className='content'>
      
        <div className={location.pathname === '/' ? 'homepage' : location.pathname === '/privacy-policy' ? "privacy": ""}>
          <ScrollToTop />

            {!location.pathname.startsWith('/search') && (
              <HeaderBar
                className='container'
                transparentMode={location.pathname === '/' ? false : false}
                isLoggedIn={isLoggedIn}
              />
            )}

            <Routes location={background || location}>
            {getSuperTokensRoutesForReactRouterDom(reactRouterDom, [PasswordlessPreBuiltUI])}
              <Route path="/">
                <Route index element={
                  <> 
                    <LandingPageActivityBased />
                    {/* <LandingPage handleBetaButtonClick={handleBetaButtonClick}/> */}
                    
      
                    <NewsletterSignup />
                    </>} />
                <Route path="/iframe-book-test" element={<iFramey />} />
                <Route path="/old-homepage" element={<LandingPageActivityBased />} />
                <Route path="search" element={<ActivitiesSearchPage isLoggedIn={isLoggedIn}/> } />
                  
                <Route path="/activities/:activityId" element={<ActivityDetailPage handleCloseModal={null} />} />
                <Route path="/stays/:accommId" element={<AccommDetailPage handleCloseModal={null} />} />
                <Route path="*" element={<NoMatch />} />
                <Route path="/area-guides/:areaGuideId/*" element={<AreaGuideDetailPage />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/trip-plan-old-intro" element={<TripPlanner />} />
                <Route path="/trip-plan" element={<LandingPageTpBased handleBetaButtonClick={handleBetaButtonClick} />} />
                <Route path="/trip-plan/:customerPortalUuid/*" element={<TpViewerPoc />} />
                <Route path="/trip-plan-proto" element={<TpSearchProto />} />
                <Route path="/contribute" element={<Contact />} />
                <Route path="/private-test" element={<SessionAuth requireAuth={true}><AboutUs /></SessionAuth>} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/plan-my-trip" element={<TripForm showTripFiltersOnly={true}/>} />
                <Route path="/trip-planner-v1" element={<TripForm showTripFiltersOnly={true}/>} />
                
              </Route>
            </Routes>

            {/* Show the activity detail page as a modal overlay when `background` is set - to keep search scroll and things UI snappy 
          Used official react router 6 modal guide, removed typescript. https://stackblitz.com/edit/react-1unvth?file=src%2FApp.js
          Also reference router 5 guide as didn't use any TS
          */}
            {background && (
              <Routes>
                <Route path="/activities/:activityId" element={<ActivityModal component={ActivityDetailPage}/>} />
                <Route path="/stays/:accommId" element={<ActivityModal component={AccommDetailPage}/>} />
              </Routes>
            )}

            {/* 
            {location.pathname != '/search' && (
              <GuidesList areaGuides={areaGuides} />
            )}
            */}

          </div>
        </div>
        {!background && !['/search', '/trip-planner-v1', '/trip-plan-proto'].includes(location.pathname) && (
          
          <Footer />
        )}
      </SiteContext.Provider>

    </>
    
  )
}





function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

export default App;

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}


