import ReactGA from "react-ga4";

function init() {
  if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID, { debug: process.env.REACT_APP_GOOGLE_ANALYTICS_DEBUG_MODE ? process.env.REACT_APP_GOOGLE_ANALYTICS_DEBUG_MODE : false })
  }
}

// Not used - just use init above from this module
function sendEvent(payload) {
  ReactGA.event(payload)
}

// Not used - just use init above from this module
function sendPageview(path) {
  ReactGA.set({ page: path }) // Do we want this? Sets a custom dimension https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference#dimension
  ReactGA.send({ hitType: "pageview", page: path });
}

export default {
  init,
  sendEvent,
  sendPageview,
}