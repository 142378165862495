import * as React from 'react';
import './featured-activities.css';
import {
    Link,
    useLocation

} from "react-router-dom";

export const FeaturedActivties = (props) => {
    const location = useLocation()
    return (
        <div className="container featured-activities">
            <div>
                <h2>New to Wildr</h2>
                <div className="h2-subtitle">Hot off the press - these activities just in!</div>
                <div className="">
                    <ul>
                        <li>
                            <Link to="/activities/217" state={{ background: location }}>
                                <div className="featured-activity-card half-height">
                                    <div className="img-gradient">
                                        <img
                                            src="images/featured-activity-images/mrr1cltwf3meagat.jpeg?tr=w-400"></img>
                                    </div>
                                    <div className="text-overlay">
                                        <h3>Ancient Tin Mine Adventure</h3>
                                        <p>Saint Just</p>
                                    </div>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/activities/1761" state={{ background: location }}>
                                <div className="featured-activity-card full-height">
                                    <div className="img-gradient">
                                        <img
                                            src="images/featured-activity-images/umfq5y2bkdh209mj.jpeg?tr=w-400"></img>
                                    </div>
                                    <div className="text-overlay">
                                        <h3>Try Freediving</h3>
                                        <p>Plymouth</p>
                                    </div>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/activities/1557" state={{ background: location }}>
                                <div className="featured-activity-card full-height">
                                    <div className="img-gradient">
                                        <img
                                            src="images/featured-activity-images/fyexaasx7lqp6euo.jpg?tr=w-400"></img>
                                    </div>
                                    <div className="text-overlay">
                                        <h3>Taster Paddle</h3>
                                        <p>Barrow upon Soar</p>
                                    </div>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/activities/1622" state={{ background: location }}>
                                <div className="featured-activity-card full-height">
                                    <div className="img-gradient">
                                        <img
                                            src="images/featured-activity-images/56y14wk4kkmiyqdh.jpeg?tr=w-400"></img>
                                    </div>
                                    <div className="text-overlay">
                                        <h3>MTB Experience Day</h3>
                                        <p>Hope Valley</p>
                                    </div>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/activities/1841" state={{ background: location }}>
                                <div className="featured-activity-card full-height">
                                    <div className="img-gradient">
                                        <img
                                            src="images/featured-activity-images/joseph-pearson-2cbNxO4drbE-unsplash.jpg?tr=w-400"></img>
                                    </div>
                                    <div className="text-overlay">
                                        <h3>Wild Swimming</h3>
                                        <p>Derwent Water</p>
                                    </div>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/activities/597" state={{ background: location }}>
                                <div className="featured-activity-card half-height">
                                    <div className="img-gradient">
                                        <img
                                            src="images/featured-activity-images/46p4wx5tmxd39xvh.jpeg?tr=w-400"></img>
                                    </div>
                                    <div className="text-overlay">
                                        <h3>Llama Trekking Adventure</h3>
                                        <p>Keswick</p>
                                    </div>
                                </div>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
