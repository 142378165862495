import * as React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
} from "react-router-dom";
import './guides-list.css';

/*
We don't show featured guides as these are shown in the area-guide-grid
*/

export const GuidesList = (props) => {
    return (
        <div className="container other-guides-list">
            <ul>
                {props.areaGuides.map((item, index) => {
                    if (item.featured === false) {
                        return (
                            <li key={item.id}>
                                <Link to={`/area-guides/${item.id}/${item.slug}`} state={{ titleFromLink: item.title }} >{item.title}</Link>
                            </li>
                        )
                    }
                })}
            </ul>
        </div>
    )
}
