import * as React from 'react';
import './activity-category-grid.css';
import {
    Link
} from "react-router-dom";

export const CategoyGrid = (props) => {
    const cats = [
        {
            name: 'Wild swimming',
            subtitle: '',
            img: `/images/activity-category-images/outdoor-swim-iStock-1262791790.jpg`,
            link: `/search?&cats=official-bathing-spots,wild-swim`
        },
        {
            name: 'Wild Saunas',
            subtitle: '',
            img: `/images/activity-category-images/wild-sauna-clay-banks-25VmaKsYifM-unsplash.jpg`,
            link: `/search?&cats=wild-saunas`
        },
        {
            name: 'Canoeing',
            subtitle: '',
            img: `/images/activity-category-images/kayak-iStock-501813235.jpg`,
            link: `/search?&cats=canoe`

        },
        {
            name: 'Climbing',
            subtitle: '',
            img: `/images/activity-category-images/rock-climbing-iStock-1250492818.jpg`,
            link: `/search?&cats=climb`
        },
        {
            name: 'Bothies (coming soon)',
            subtitle: 'coming soon',
            img: `/images/activity-category-images/bothies-Corrour_Bothy_-_geograph.org.uk_-_1546559.jpg`,
            link: ``
        },
        
        /*
        {
            name: 'Vanlife',
            subtitle: '',
            img: `/images/activity-category-images/camper-iStock-1165124180.jpg`
        },*/
        {
            name: 'Camp',
            subtitle: '',
            img: `/images/activity-category-images/camping-iStock-1203824481.jpg`,
            link: `/search?&cats=camp`
        },
        {
            name: 'Paddleboarding',
            subtitle: '',
            img: `/images/activity-category-images/pb-iStock-913017692.jpg`,
            link: `/search?&cats=sup`
        },
        /*
        {
            name: 'Hot air ballooning',
            subtitle: '',
            img: `/images/activity-category-images/hab-iStock-135872655.jpg`,
            link: `search?&cats=`
        },*/
        {
            name: 'Crafts',
            subtitle: '',
            img: `/images/activity-category-images/crafts-iStock-171311449.jpg`,
            link: `/search?&cats=crafts`
        },
        {
            name: 'Bushcraft',
            subtitle: '',
            img: `/images/activity-category-images/bushcraft-iStock-1312234823.jpg`,
            link: `/search?&cats=bushcraft`
        },
        {
            name: 'Wild Campsites (coming soon)',
            subtitle: '',
            img: `/images/activity-category-images/wild-campsites-IMG_8740.jpg`,
            link: ``
        },
        {
            name: 'Cycling',
            subtitle: '',
            img: `/images/activity-category-images/mtb-iStock-547146416.jpg`,
            link: `/search?&cats=bike,mtb`
        },
        /*
        {
            name: 'Wild Swimming',
            subtitle: '',
            img: `/images/activity-category-images/wild-swim-iStock-1043818858.jpg`,
            link: `search?&cats=`
        },
        {
            name: 'Hill Walking',
            subtitle: '',
            img: `/images/activity-category-images/hill-walking-iStock-1049861528.jpg`,
            link: `/search?&cats=walk`
        },*/
    ]
    return (
        <section className="activity-categories">
            <div className="container">
                <h2>Popular activities</h2>
                <div className="h2-subtitle">Some of our most loved Wildr things to do</div>

                    <div className="photos">
                        {cats.map(item => {
                            return <Link to={item.link} className="category-card-stretchy-wrapper" key={item.name}>
                                <div className="category-card-x">
                                    <div className="image-container"><img src={item.img}></img>
                                    </div>
                                    <h3>{item.name}</h3>
                                </div>
                            </Link>
                        })}
                    </div>
            </div>
        </section>
    )
}
