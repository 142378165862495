import * as React from 'react';
import {
    BrowserRouter as Router,
    Link,
    useLocation,
    useNavigate,
    useParams,
} from "react-router-dom";
import axios from "axios";

import { ActivityLabels } from '../components/labels-strip/labels-strip'
import { JourneyTimes } from "../components/journey-times/journey-times"
import { mapIcons, mapIconsSelected } from '../components/map/mapIcons'
import { MapContainer, TileLayer, Marker, Polyline } from 'react-leaflet'
import './accomm-detail-page.css';
import '../header-bar.css';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import { ReactComponent as CloseButton } from '../../assets/icons/close-button.svg';
import ReactMarkdown from 'react-markdown'



import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import DoneIcon from '@mui/icons-material/Done';
export const AccommDetailPage = (props) => {

    const { accommId } = useParams();
    const [accommDetail, setAccommDetail] = React.useState(null);
    const [isLoadedDetail, setIsLoadedDetail] = React.useState(false)
    const [errorFetchingDetail, setErrorFetchingDetail] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState()

    const location = useLocation()

    const [isLightboxOpen, setLightboxIsOpen] = React.useState(false);
    const [lightboxOpenSlideIndex, setLightboxOpenSlideIndex] = React.useState(null);


    // We re-use some props from search if linked from there. Eg travel times that are only relevant in context of the trip or search
    var namePassedIn = null
    var travelTimePassedIn = null
    var travelTimeLabelPassedIn = null
    if (location.state != null) {
        if (location.state.hasOwnProperty('name')) {
            namePassedIn = location.state.name
        }
        if (location.state.hasOwnProperty('travelTime')) {
            travelTimePassedIn = location.state.travelTime
        }
        if (location.state.hasOwnProperty('travelTimeLabel')) {
            travelTimeLabelPassedIn = location.state.travelTimeLabel
        }
    }

    const fetchAccommDetail = () => {
        setIsLoadedDetail(false)

        let url = process.env.REACT_APP_WILDR_PUBLIC_API + "/accommodation/" + accommId + "/"
        axios.get(url).then((response) => {
            setAccommDetail(response.data)
            setIsLoadedDetail(true)
            document.title = response.data.title + " | Wildr"

        }).catch(error => {
            console.log(error)
            setErrorFetchingDetail(true)
            setErrorMessage(error.message)
            /*
            if (error.response.status === 404) {
                console.log("404")
              }
              console.log("Other");*/
        });
    }


    React.useEffect(() => {
        fetchAccommDetail();
    }, [accommId]);

    // Get the general water info, do extra GA
    React.useEffect(() => {
    }, [accommDetail]);

    const capitalizeFirstLowercaseRest = str => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };




    React.useEffect(() => {
        if (lightboxOpenSlideIndex != null) {
            setLightboxIsOpen(true)
        }
    }, [lightboxOpenSlideIndex]);


    return (
        <div className='activity-details'>
            {accommDetail &&
                <Lightbox
                    open={isLightboxOpen}
                    thumbnails={{
                        border: 0,
                    }}
                    close={() => setLightboxIsOpen(false)}
                    animation={{ fade:250, swipe:750, navigation:0 }}
                    index={lightboxOpenSlideIndex}
                    plugins={[Thumbnails, Captions, Fullscreen, Slideshow, Video, Zoom]} // Removed Thumbails for simplicity
                    slides={accommDetail.images && accommDetail.images.map(slide => (
                        {
                            "src": slide.url,
                            // "title": "Slide titleasdasdasd", // Shows at top of screen, overkill, use description only
                            // "description": ""
                        }
                    ))}
                />
            }

            <div>
                {errorFetchingDetail ?
                    <div>{errorMessage}</div>
                    :
                    <div>{isLoadedDetail ?
                        <div className='activity-detail-container' >
                            <div className='minimal-back-button-container'>
                                {props.handleCloseModal ?
                                    <div className='minimal-back-button' style={{ display: "block" }} onClick={props.handleCloseModal}><CloseButton /></div>
                                    :
                                    <div className='minimal-back-button' style={{ display: "block" }}><Link to="/trip-plan-proto"><CloseButton /> </Link></div>
                                }
                            </div>
                            {/* 
                            This logic hides the slider options if only one image. It used to also change image sizes but was never built for mobile. 
                            */}
                            <Splide options={accommDetail.images.length > 1 ?  // splide options https://splidejs.com/guides/options/
                                {
                                    height: '240px', // For some reason also need to set this in css
                                    width: '100%',
                                    autoWidth: true,
                                    gap: '12px',
                                    // borderadius: '12px', set in activity detail css instead
                                } : {
                                    height: '240px', // For some reason also need to set this in css
                                    width: '100%',
                                    autoWidth: true,
                                    gap: '12px',
                                    arrows: false,
                                    drag: false,
                                    pagination: false
                                    //type: 'loop'
                                }
                            } onClick={(xyz, slide) => { setLightboxOpenSlideIndex(slide.index) }}>

                                {/* Add a resolution limiter url param to any internal images for the preview. eg ?tr=h-240*/}
                                {
                                    accommDetail.images.map(slide => (
                                        <SplideSlide key={slide.url}>
                                            <img src={`${slide.url}?tr=h-240`} alt="" style={accommDetail.images.length > 1 ? { height: "240px" } : { height: "240px" }} />
                                        </SplideSlide>
                                    ))


                                }

                            </Splide>


                            <div className='activity-info'>
                                <div className="title-section left-hand">
                                    <h1>{namePassedIn ? namePassedIn : accommDetail.title}</h1>
                                </div>


                                <div className="description-section left-hand">
                                    <ReactMarkdown>{accommDetail.description ? accommDetail.description : accommDetail.short_description}</ReactMarkdown>
                                </div>
                                <div className="map-section">
                                    <div className="activity-details-map">
                                        <MapContainer center={[accommDetail.location.latitude, accommDetail.location.longitude]} zoom={13} scrollWheelZoom={false}>
                                            <TileLayer
                                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                            />
                                            <Marker
                                                position={[accommDetail.location.latitude, accommDetail.location.longitude]}
                                                key={accommDetail.id}
                                            //icon={mapIconsSelected.hasOwnProperty(accommDetail.category.slug) ? mapIconsSelected[accommDetail.category.slug] : mapIconsSelected['blank']}
                                            >
                                            </Marker>
                                        </MapContainer>
                                    </div>
                                </div>
                            </div>

                        </div>
                        : <div>loading...</div>
                    }
                    </div>

                }
            </div>
        </div>
    )
}

