import * as React from 'react';
import './landing-page.css';
import customiseScreen from '../../../assets/images/landing-page/custom_social.jpg?tr=w-850';
import fiveOptionsDesktop from '../../../assets/images/landing-page/options_l.jpg'
import fiveOptionsMobile from '../../../assets/images/landing-page/options_m.jpg?tr=w-850'
import fanSpreadScreen from '../../../assets/images/landing-page/fan-spread.jpg'
import bookScreen from '../../../assets/images/landing-page/book.jpg'
import aboutYou from '../../../assets/images/landing-page/aboutyou.jpg'
import socialScreen from '../../../assets/images/landing-page/social.jpg'

export const LandingPageTpBased = (props) => {
    return (
        <div className="landing-page">

            {/* 
        <div className="five-options">    
            <div className="options-images">
                    <img className="snapshot-mobile" src={fiveOptionsMobile} alt=""></img>
                    <img className="snapshot-desktop" src={fiveOptionsDesktop} alt=""></img>
            </div>
            <header>    
                <div className="container opaque">
                    <div className='options-title-block'>
                        <h2>Customised trips built just for you</h2>
                        <div className="h2-subtitle">Create your perfect adventure in just a few minutes</div>
                    </div>
                    <div id="khYdwusd" style={{ fontSize: 20 }} className="solid-button">
                        Try the beta!
                    </div>
                </div>
            </header>
        </div>
        */}

            <div className="container two-column cta-block">
                <div className="left-hand">
                    <h1>Adventure trips built around you</h1>
                    <p className="">Find your dream trip with just a few clicks. Sign up for early access!</p>
                    <div className="solid-button" onClick={props.handleBetaButtonClick}>
                        Sign up
                    </div>
                </div>
                <div className="right-hand">
                    <img src={fanSpreadScreen} alt=""></img>
                </div>
            </div>

            {/* 
            <div className="container">
                <h2>Step 1. Tell us about your ideal adventure...</h2>
                <p className="">Subtitle here. Make it quirky and whatever. Maybe a bit longer.</p>
                <div className="">
                    <img src={aboutYou} alt=""></img>
                </div>
                <div className="">
                </div>
            </div>
            */}
            

            <div className="container two-column aboutyou">
                <div className="right-hand">
                    <img src={aboutYou} alt=""></img>
                </div>
                <div className="left-hand">
                    <h2>Tell us about your ideal adventure...</h2>
                </div>
            </div>
            
            {/*
            <div className="five-options container">
                <div className="options-images">
                    <img className="snapshot-mobile" src={fiveOptionsMobile} alt=""></img>
                    <img className="snapshot-desktop" src={fiveOptionsDesktop} alt=""></img>
                </div>

                        <div className='options-title-block'>
                            <h2>....and we'll build you 5 entirely personalised trips</h2>
                            <p className="">Instantly. Complete with activities & stays, journey times and more</p>
                        </div>
            </div>
        */}
            <div className="container options">
            <h2>instantly receive 5 bespoke trips</h2>
                <div className="options-images">
                    <img className="snapshot-mobile" src={fiveOptionsMobile} alt=""></img>
                    <img className="snapshot-desktop" src={fiveOptionsDesktop} alt=""></img>
                </div>
                
            </div>


            <div className="container two-column customise">
                <div className="left-hand">
                    <h2>Customise and collaborate</h2>
                    <p className="">Swap in activities and get your friends involved. </p>
                </div>
                <div className="right-hand">
                    <img src={customiseScreen} alt=""></img>
                </div>
            </div>
            
            <div className="container two-column book">
                <div className="right-hand">
                    <img src={bookScreen} alt=""></img>
                </div>
                <div className="left-hand">
                    <h2>All booked!</h2>
                    <p className="">Your next adventure awaits.</p>
                </div>
            </div>


            {/* 
            <div className="container two-column">
                <div className="right-hand">
                    <img src={socialScreen} alt=""></img>
                </div>
                <div className="left-hand">
                    <h2>Share etc etc</h2>
                    <p className="">Todo</p>
                </div>
            </div>
            */}

        </div>
    )
}
