import React, {useEffect, useState} from "react";
import Divider from '@mui/material/Divider';
import ActivityResult from "../components/activity-result"
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import DriveEtaIcon from '@mui/icons-material/DriveEta';

/*

After everything else has loaded. 
If there are any gov swim spots
Pull the water data
Pass it to ActivitiesList
Then
a) match the relevant water data and pass it in
b) pass all the water data and match inside. 

We passed it in as props.waterQualityList
*/ 

export const ActivitiesList = (props) => {
    return (
        <div id="activities-list">
          <div className="result-list">
          {props.activities.results && props.activities.results.length == 0 && props.searchLocationCoords ?
              <p> No results - try another UK location{props.selectedCategory && props.selectedCategory.length > 0 && ' or filter by fewer categories'}! </p> 
              : props.activities.results && props.activities.results.length == 0 &&
   
              <p> No results - try zooming out a bit{props.selectedCategory && props.selectedCategory.length > 0 && ' or filter by fewer categories'}! </p> 
          }
            {props.activities.results && props.activities.results.map(item => {
              let activityWaterQuality = null
                if (props.waterQualityList){
                  if (item.source && item.source.slug === 'gov-bathing'){
                    props.waterQualityList.map(waterQuality => {
                      if (item.partner_id === waterQuality.eubwidNotation){
                        activityWaterQuality = waterQuality
                      }
                    })
                }
              } 

              return <ActivityResult key={item.id} item={item} hoverActivityId={props.hoverActivityId} clickHandler={props.clickHandler} waterQuality={activityWaterQuality} 
                activityMouseEnterHandler={props.activityMouseEnterHandler} activityMouseLeaveHandler={props.activityMouseLeaveHandler}  handleHighlightedActivityOutOfView={props.handleHighlightedActivityOutOfView}
                hoverActivityOriginatedInMap={props.hoverActivityOriginatedInMap}/>
            })}
          </div>
        </div>
    );
}


