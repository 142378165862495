import { useTheme, useMessages } from "@quillforms/renderer-core";
import { useEffect } from "react";
import tinyColor from "tinycolor2";
import { css } from "emotion";
import classnames from "classnames";

import {SearchBox} from '../../../components/location-search/search-box/search-box'



let timer;
const CustomLocationBlock = (props) => {
  const {
    id,
    attributes,
    setIsValid,
    setIsAnswered,
    setValidationErr,
    isActive,
    val,
    setVal,
    next
  } = props;

  const { required, start, end } = attributes;
  const messages = useMessages();
  const theme = useTheme();
  const answersColor = tinyColor(theme.answersColor);

  const checkfieldValidation = (value) => {
    if (required === true && (!value || value === "")) {
      setIsValid(false);
      setValidationErr(messages["label.errorAlert.required"]);
    } else {
      setIsValid(true);
      setValidationErr(null);
    }
  };

  const items = [];
  for (let i = start; i <= end; i++) {
    items.push(i);
  }

    const handleSearchLocationChange = (locationLatLong, locationSearchInputText) => {
        // setVal(location);
        // setIsAnswered(true);
        setVal({'locationLatLong': locationLatLong, 'locationSearchInputText': locationSearchInputText});
        timer = setTimeout(() => {
            setIsAnswered(true);
            next();
        }, 750);
    }

  useEffect(() => {
    if (!isActive) {
      clearTimeout(timer);
    }
  }, [isActive]);

  useEffect(() => {
    checkfieldValidation(val);
  }, [val]);

  return (
    <>
    <SearchBox placeholder={'Trip start location'} searchLocationChange={handleSearchLocationChange} locationSearchText={null} selectedCategorySlugs={[]} fieldClass={null}/>
      <div
        className={css`
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          margin-top: 15px;
          font-size:4px;
        `}
      >
      </div>
    </>
  );
};
export default CustomLocationBlock;
