import * as React from 'react';
import './header-bar.css';
import { ReactComponent as WildrLogo } from '../assets/images/wildr-orange.svg';
import { Menu } from './components/menu/menu'
import {CheckoutButton} from './components/checkout/checkout-button'
import {
    BrowserRouter as Router,
    Link,
    useLocation,
    useNavigate,
    useParams
  } from "react-router-dom";

export const HeaderBar = (props) => {
    /*  
        CURRENTLY THE SEAERCH BOX IS UNUSED AS WE LOAD A DIFFERENT HEADER IN THE SEARCH PAGE. 
        It's a pain to own the state at a high level and pass it down with so many map interactions
        Move to redux in future
        OR move it out of top bar. 
        */
    const [transparentNavbar, setTransparentNavbar] = React.useState(props.transparentMode)
      
    React.useEffect(() => {
        if (props.transparentMode == true) {
            setTransparentNavbar(true); 
        }
        
    }, [props.transparentMode])

    const changeNavbarColor = () =>{
        if(window.scrollY >= 80){
            setTransparentNavbar(false);
        }
        else{
            setTransparentNavbar(true);
        }
     };

     if (props.transparentMode){
        window.addEventListener('scroll', changeNavbarColor);
     }

    return(
    <div className={transparentNavbar && props.transparentMode? "navbar horizontal-drop-shadow sticky transparent" : "navbar horizontal-drop-shadow sticky opaque"}>
        <div className='navbar-container'>
            <Link to="/"><WildrLogo className="wildr-logo"/></Link> 
            {/*
            <CheckoutButton/>
    */}
            <Menu transparentMode={props.transparentMode} transparentNavbar={transparentNavbar} isLoggedIn={props.isLoggedIn}></Menu>
        </div>
    </div>
)
}
