import React from 'react';
import './labels-strip.css';
import Rating from '@mui/material/Rating';


export const ActivityLabels = (props) => {
  const [waterQualityRating, setWaterQualityRating] = React.useState(null);

  // Convert gov water quality descriptions to a rating
  React.useEffect(() => {
    if (props.waterQuality) {
      var rating = null
      var ratingName = props.waterQuality.latestComplianceAssessment.complianceClassification.name._value.toLowerCase()
      if(ratingName === "poor"){
        rating = 0
      }
      else if (ratingName === "sufficient"){
        rating = 1
      }
      else if (ratingName === "good"){
        rating = 2
      }
      else if (ratingName === "excellent"){
        rating = 3
      }
      setWaterQualityRating(rating)
    }
  }, [props.waterQuality])

  return (
    <div className="labels-strip">
      {props.item.category.slug != "beyonk-temp" ? <div className="labels">{props.item.category.icon_url != "" ? <img className='icon' src={props.item.category.icon_url}></img> : ""}{props.item.category.name}</div> : ""}
      {props.item.pricing.is_free && <div className="labels highlighted-label blue-highlight" >Free!</div>}
      {props.item.natural_next_bookable_day && <div className="labels highlighted-label orange-highlight">Instant booking!</div>}
      {props.item.natural_next_bookable_day && <div className="labels">Next on {props.item.natural_next_bookable_day}</div>}
      {props.item.pricing.minimum_pounds_rounded_up && <div className="labels">From £{props.item.pricing.minimum_pounds_rounded_up}</div>}
      {props.item.source && props.item.source.icon_url != "" && props.item.source.slug != "beyonk" && <img className='source-icon' src={props.item.source.icon_url}></img>}
      {props.waterQuality && props.waterQuality.latestComplianceAssessment && <div className="labels">{props.waterQuality.latestComplianceAssessment.complianceClassification.name._value} water quality  <Rating name="read-only" value={waterQualityRating} max={3} defaultValue={0} readOnly size="small" /></div>}
    </div>

  )
}