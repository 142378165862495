import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import * as React from 'react';
import { addDays } from 'date-fns';
import { useState } from 'react';
import './dates-selector.css';
export const DateSelector = (props) => {

    /*
    // State lifted out
    const [state, setState] = useState([
        // https://github.com/hypeserver/react-date-range/issues/330#issuecomment-802693503
        // Doesn't support unselecting dates
        {
            startDate: null,
            endDate: new Date(""),
            key: 'selection'
        }
    ]);
    */

    return (
        <DateRangePicker
            onChange={item => props.setCalendarState([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={props.calendarState}
            direction="horizontal"
            staticRanges={[]}
            inputRanges={[]}
            startDatePlaceholder='depart'
            endDatePlaceholder='return'
        />
    )
}