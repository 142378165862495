import * as React from 'react';
import { GoogleMapsGeoSearch } from '../location-search-materialui'

// TODO add in use my location. filters. etc. 

export const SearchBox = (props) => {
	return (
		<GoogleMapsGeoSearch
			placeholder={props.placeholder}
			searchLocationChange={props.searchLocationChange}
			locationSearchText={props.locationSearchText}
			fieldClass={props.fieldClass}
			selectedCategorySlugs={props.selectedCategorySlugs}
		/>
	)
};