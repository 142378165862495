import * as React from 'react';
import './category-filter.css';
import axios from "axios";
import Select, { components } from "react-select";

/*
// Custom styling reference - remove once implementation complete
const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
};

const groupBadgeStyles = {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center"
};

const formatGroupLabel = data => (
    <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
);
*/
export const CategoryFilter = (props) => {
    const [categories, setCategories] = React.useState([]);
    const [isLoadedCategories, setIsLoadedCategories] = React.useState(false);
    const [isLoadedCategoriesError, setIsLoadedCategoriesError] = React.useState(null);

    var customStyles = {
        valueContainer: (provided, state) => ({
            ...provided,
            textOverflow: "ellipsis",
            maxWidth: "90%",
            whiteSpace: "nowrap",
            overflow: "hidden",
        })
    };

    var multiValueContainer = ({ selectProps, data }) => {
        // custom code from here to update style, hide overlow etc
        // https://codesandbox.io/s/v638kx67w7?file=/index.js:2301-2322
        // Modified to show a (+x others) message for more than 1 category. 
        const label = data.label;
        const allSelected = selectProps.value;
        const index = allSelected.findIndex(selected => selected.label === label);
        const isLastSelected = index === allSelected.length - 1;
        // const labelSuffix = isLastSelected ? ` (${allSelected.length})` : ", ";
        const labelSuffix = isLastSelected ? ` (+${allSelected.length - 1} others)` : "";
        var val = "";
        if (index > 0 && isLastSelected) {
            val += `${labelSuffix}`
        }
        if (index == 0) {
            val += `${label}`
        }
        return val
    };

    const fetchCategories = () => {
        setIsLoadedCategories(false)
        let url = process.env.REACT_APP_WILDR_PUBLIC_API + "/activity_categories"
        axios.get(url).then((response) => {
            // filter the response.data list to only include categories that have use_as_trip_filter = true
            if(props.showTripFiltersOnly){
                let filteredCategories = response.data.filter(category => category.use_as_trip_filter == true)
                setCategories(filteredCategories)
            }
            else(
                setCategories(response.data)
            )
            setIsLoadedCategories(true)
        }).catch(error => {
            console.log("error", error)
            setIsLoadedCategoriesError(error)
        });
    }

    // On page load this fetches categories
    React.useEffect(() => {
        fetchCategories()
    }, [])


    return (
        <div className="category-filter">
            {categories.length > 0 && props.queryParamsLoadedToState &&
                <Select
                    isMulti
                    hideSelectedOptions={false}
                    defaultValue={props.selectedCategorySlugs &&
                        categories.filter(category => props.selectedCategorySlugs.includes(category.slug))
                            .map(item => ({ 'value': item.slug, 'label': item.name }))
                    }
                    placeholder={"Filter by category..."}
                    classNamePrefix="react-select"
                    // options equals map of categories if item.showTripFiltersOnly is true condititoanl
                    options={categories.map(item => ({ 'value': item.slug, 'label': item.name }))}
                    
                    onChange={props.handleCategoryChange}
                    components={{
                        MultiValueContainer: multiValueContainer
                        // Option: CustomOption,
                    }}
                    styles={customStyles}
                />
            }
        </div>
    )
}


