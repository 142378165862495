import React from "react"
import { useLocation } from "react-router-dom"

import analytics from "./analytics"


// Used to auto send new page views for any page apart from those in pathsToIgnoreSendPage
export default function useGoogleAnalytics() {
  const location = useLocation()


  // TODO doesn't work, need to update code to do starts with this,. 
  // const pathsToIgnoreSendPage = ['/search', '/activity'] 
  /* Stop every search page history even, eg by a new search param, being counted as
  a new page view. Instead we manually record first page view and then send a different even
  for subsequent search params changes. 
  */
  
  // This is needed somewhere in main APP even if doing enhanced measurement
  React.useEffect(() => {
    analytics.init()
  }, [])

  /*
  // The below isn't needed if doing GA4 enhanced measurement as it cathes page views anyway and seems 
  // to include more data on referrer etc. 
  React.useEffect(() => {
    console.log("location.pathname", location.pathname)
    // console.log("location.search", location.search)
    const currentPath = location.pathname + location.search
    console.log('currentPath',currentPath)
    if(!pathsToIgnoreSendPage.includes(location.pathname)){
      console.log('Sending generic page view automatically')
      // analytics.sendPageview(currentPath)
    }
  }, [location])
  */
}

