import * as React from 'react';
import { MapContainer, TileLayer, Marker , LayersControl, Polyline} from 'react-leaflet'
import L from 'leaflet';
import { useMapEvents } from "react-leaflet";
import { useNavigate, useLocation } from 'react-router-dom';
import { mapIcons, mapIconsSelected } from './mapIcons'
import debounce from 'lodash.debounce';
import './map.css';
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import RoutineMachine from "./RoutineMachine";

export const MapComponent = (props) => {

  
  const [map, setMap] = React.useState(null);
  // const [zoomLevel, setzoomLevel] = React.useState(11);
  const location = useLocation()
  const navigate = useNavigate();
  
  const activityMarkersRef = React.useRef([]);

  React.useEffect(() => {
    activityMarkersRef.current = activityMarkersRef.current.slice(0, props.activities.length);
  }, [props.activities.results]);

  function MapEventManager() {
    // copied from here https://react-leaflet.js.org/docs/api-map/#usemapevents
    // Used to detect when zoom changes. Tried inline in the component events but anything but 'click' seems to be ignored

    // Used to track zoom changes, use to be used to maintain zoom level even when a new search is carried out

    const map = useMapEvents({
      zoomend: (e) => {
        //props.handleZoomLevelChange(e.target.getZoom())
        // console.log("ZOOM changed")
      },
      dragend: (e) => {
        // console.log("DRAG changed")
      },
      // Fired when the center of the map stops changing (e.g. user stopped dragging the map or after non-centered zoom).
      moveend: (e) => {
        props.handleMapBoundsChange(e.target.getBounds())
      }
    })
    return null
  }

  // If the map focus coors change eg if someone hovers on a result card for long enough, zoom to it
  React.useEffect(() => {
    if (map != null) {
      if (props.mapFocus) {
        // Wrapped flyTo in a timeout to deal with a firefox debugger bug: https://github.com/facebook/react/issues/17355
        // was getting Should not already be working. But then adding the timeout made the map a bit buggier - solution remove the breakpoint. 
        /*
        setTimeout(function() {
          map.flyTo([props.mapFocus.lat, props.mapFocus.long], props.mapFocus.zoom)
        }, 1000)
        */

        // This is what you need to fly to new locatoins. BUT without triggering the move.
        // So instead do it in controoler.
        // On search loc change, and map, fly to?
        map.flyTo([props.mapFocus.lat, props.mapFocus.long], props.mapFocus.zoom)
      }
    }
  }, [props.mapFocus, map])


  React.useEffect(() => {
    if (map != null) {
      map.invalidateSize()
    }
  }, [props.useMapView])

  // On activity highlight, if not in map view, focus on it. At the moment we do this if the list is the highlight cause, or the map. The latter we don't need to do as we know it's in the map view already    
  // If you choose to store the zoom level again, why not move this into the controller?
  React.useEffect(() => {
    debouncedHandleMouseEnterMarker.cancel()
    if (map != null) {
      if (!props.useMapView) {
        if (props.hoverActivityId) {

          const location = props.activities.results.find(x => x.id === props.hoverActivityId).location
          if (!map.getBounds().contains([location.latitude, location.longitude])) {
            debouncedHandleMouseEnterMarker({ 'lat': location.latitude, 'long': location.longitude, 'zoom': 11 })
          }
        }
      }
    }
  }, [props.hoverActivityId])

  const debouncedHandleMouseEnterMarker = React.useCallback(debounce((loc) => {
    props.handleMapFocusChange(loc)
  }, 500), [])


  // TODO do you want to move this up to the parent controller? Then could load some info from the already loaded search data: // state={{ background: location, activityInfoFromSearch: props.item}}state={ background: location, activityInfoFromSearch: props.item}
  const handleOnClick = React.useCallback((e) => {
    var markerWildrId = e.target.options.wildrActivityId;
    return navigate('/activities/' + markerWildrId, { replace: false, state: { background: location } })
  }, [navigate]);

  const handleMapButtonClick = () => {

    if (map) {
      props.handleSearchWithinBounds(map.getBounds())
    }

      /*
      const location = props.activities.find(x => x.id === props.hoverActivityId).location
      if (!map.getBounds().contains([location.latitude, location.longitude])) {
        debouncedHandleMouseEnterMarker({ 'lat': location.latitude, 'long': location.longitude, 'zoom': 11 })
      }
      */
    
  }

  
  return (
    <div className="map">
      {props.activities.results &&
        <div className="info-overlay hidden-mobile">
          {props.activities.next &&
            <div className={'results-info'}>Too many results <span className='stats'>{`- showing ${props.activities.results.length}/${props.activities.count}`} </span>- zoom in or search by location to see them all</div>
          }
          <div className='map-move-options hidden-mobile'>
            <button className="pill-button secondary condensed" title="Contribute" href="" disabled={!props.updateSearchButtonEnabled} onClick={handleMapButtonClick}>Search here</button>
            <label>
              <input type="checkbox"
                onChange={e => props.handleSearchAsMapMoveToggle(e.target.checked)} />
              Search as I move
            </label>
          </div>
        </div>
      }
      <MapContainer center={[props.mapFocus.lat, props.mapFocus.long]} zoom={props.mapFocus.zoom}
        scrollWheelZoom={true} whenCreated={setMap} ref={setMap}>
        <ReactLeafletGoogleLayer useGoogMapsLoader={false} type={'terrain'} />
        <MapEventManager />
        {/* Remove ReactLeafletGoogleLayer and enable this to go back to normal non gmaps leaflet. 
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
         */}
        {props.mapHomeCoords && <Marker position={[props.mapHomeCoords.lat, props.mapHomeCoords.long]}></Marker>}

        {props.activities.results && props.activities.results.map((object, i) =>
          <Marker
            position={[object.location.latitude, object.location.longitude]}
            icon={props.hoverActivityId === object.id ? mapIconsSelected[object.category.slug] : mapIcons.hasOwnProperty(object.category.slug) ? mapIcons[object.category.slug] : mapIcons['blank']}
            wildrActivityId={object.id}
            eventHandlers={{
              click: (e) => { // event types listed here https://leafletjs.com/reference-1.7.1.html#interactive-layer-click
                handleOnClick(e)
              },
              mouseover: (e) => { props.activityMarkerMouseEnterHandler(e) },
              mouseout: (e) => { props.activityMarkerMouseLeaveHandler(e) },
              moveend: (e) => { console.log("MOVEND") }
            }}
            key={object.id}
          >
            {/* <Popup>{object.name}</Popup> */}
          </Marker>)
        }
      
      </MapContainer>
    </div>
  )
}


