import * as React from 'react';
import {
    BrowserRouter as Router,
    Link,
    useLocation,
    useNavigate,
    useParams,
    useRouteMatch
  } from "react-router-dom";
import axios from "axios";

import { EmbeddedSearchBox } from './components/location-search/embedded-search-box/embedded-search-box'
import ActivityResult from "./components/activity-result"
import './area-guide-detail.css';

export const AreaGuideDetailPage = (props) => {
    const { areaGuideId } = useParams();
    const [areaGuide, setAreaGuide] = React.useState({});
    const [activities, setActivities] = React.useState([]);
    const [isLoadedAreaGuide, setIsLoadedAreaGuide] = React.useState(false)
    const [isLoadedActivities, setIsLoadedActivities] = React.useState(false)

  
    var titleFromLink = null
    const location = useLocation()
    if (location.state != null){
      var { titleFromLink } = location.state
    }
    
  
    const fetchAreaGuide = () => {
        setIsLoadedAreaGuide(false) 
        let url = process.env.REACT_APP_WILDR_PUBLIC_API + "/area_guides/" + areaGuideId + "/"
        axios.get(url).then((response) => {
        setAreaGuide(response.data)
        setIsLoadedAreaGuide(true)
        document.title = response.data.title
        }).catch(error => {
            console.log("error", error)
            // setisLoadedStaticLocationError(error)
        });
    }

    const fetchActivities = () => {

        // parse areaGuide.activities into a list of params
        var activityIds = [];
        var activityIdsCommaSep = ""
        for (var key in areaGuide.activities) {
                activityIds.push( areaGuide.activities[key].id );
                activityIdsCommaSep += areaGuide.activities[key].id
        }

        setIsLoadedActivities(false) 
     
        
        fetch(process.env.REACT_APP_WILDR_PUBLIC_API + "/activities?id=" + activityIds.join(','))
        .then(res => res.json())
        .then(
          (result) => {
            setActivities(result.results);
            setIsLoadedActivities(true);

        
          },
          (error) => {
            console.log("error", error)
            // setLoadActivitiesError(error);
          }
        )

    }


    React.useEffect(() => {
      fetchAreaGuide();
     }, [areaGuideId]);
     
    // Once the area guide is loaded, fetch activities
    React.useEffect(() => {

        if (areaGuide.activities){
          setIsLoadedActivities(false) 
          
          var activityIds = [];
          for (var key in areaGuide.activities) {
                  activityIds.push( areaGuide.activities[key].id );
          }
          
          fetch(process.env.REACT_APP_WILDR_PUBLIC_API + "/activities?id=" + activityIds.join(','))
          .then(res => res.json())
          .then(
            (result) => {
              setActivities(result.results);
              setIsLoadedActivities(true);

          
            },
            (error) => {
              console.log("error", error)
              // setLoadActivitiesError(error);
            }
          )

        }
    }, [areaGuide]);

    return(
      <div className='area-guide'>

        <div className='header'>
              <div className="hero">
                <h1>{areaGuide.title ? areaGuide.title : titleFromLink}</h1>  
                <h4>{areaGuide.subtitle}</h4>   
                
                <div className='hompage-location-search-wrapper'>
                <div className='hero__search-wrapper'>
                    <EmbeddedSearchBox fieldClass='heroSearch'/>
                </div>
                </div>
              </div>

     
              
              
        </div>
        <section className='container area-guide'> 
            {isLoadedActivities ? <div>
              <ul>
                {activities.map(item => {
                  let activityWaterQuality = null
                    if (props.waterQualityList){
                      if (item.source && item.source.slug === 'gov-bathing'){
                        props.waterQualityList.map(waterQuality => {
                          if (item.partner_id === waterQuality.eubwidNotation){
                            activityWaterQuality = waterQuality
                          }
                        })
                    }
                  } 
                  /*
                  if (item.source.slug === 'gov-bathing') 
                  try and find a match? But it won't be there till later? 
                  */
                  return  <li><ActivityResult key={item.id} item={item} clickHandler={null} waterQuality={null}/></li>
                })}
              </ul>
            </div> : <div>loading</div>}
        </section>
      </div>)
        
}
