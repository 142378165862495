
import React, { useState, useEffect, useRef } from 'react';
// import './trip-planner.css';
import { CategoyGrid } from '../category-image-grid/activity-category-grid'
import { FeaturedActivties } from '../featured-activities/featured-activities'
import { AboutUsOverview } from '../about-us-overview/about-us-overview'
import { AreaGuideGrid } from '../area-guide-grid/area-guide-grid';
import { GuidesList } from '../guides-list/guides-list';
import { HomepageLocationSearch } from '../homepage-location-search/homepage-location-search'
import { HeroSearch } from '../hero-search/hero-search'
import axios from "axios";

export const LandingPageActivityBased = (props) => {
    const [areaGuides, setAreaGuides] = useState([]);
  const [isLoadedAreaGuides, setIsLoadedAreaGuides] = useState(false);
  const [isLoadedAreaGuidesError, setIsLoadedAreaGuidesError] = useState(null);
  const fetchGuides = () => {
    setIsLoadedAreaGuides(false)
    let url = process.env.REACT_APP_WILDR_PUBLIC_API + "/area_guides/"
    axios.get(url).then((response) => {
      setAreaGuides(response.data)

      setIsLoadedAreaGuides(true)

    }).catch(error => {
      console.log("error", error)
      setIsLoadedAreaGuidesError(error)
    });
  }

  // On page load
  React.useEffect(() => {

    // fetch activities
    fetchGuides()
}, [])
    return (
        <>  
            <HeroSearch /> {/* Has the image background, headers center */}
            {/* <HomepageLocationSearch /> */} {/* No image background, headers left */}
            <CategoyGrid />
            <FeaturedActivties />
            {/* <AboutUsOverview /> */} {/* Great roundy 2 image and tex box - just need some good content */}
            <AreaGuideGrid areaGuides={areaGuides} />
            <GuidesList className="area-guides" areaGuides={areaGuides}/>
        </>
    )


}