import Geocode from 'react-geocode';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
export const getUserGeolocation = (searchLocationChange, setShowPreloader) => {
  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  const handleSuccessGeolocation = (position) => {
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);
    Geocode.setLanguage('en');
    Geocode.setLocationType('ROOFTOP');

    Geocode.fromLatLng(position.coords.latitude, position.coords.longitude)
    .then((response) => {
        let formatted_address = '';
        if (response.results.length > 0){
          formatted_address = response.results[0].formatted_address
        }
        /* Seems unneccessary when we can just set the address
        let city, state, country;
        for (let i = 0; i < response.results[0].address_components.length; i++) {
          for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
            switch (response.results[0].address_components[i].types[j]) {
              case "locality":
                city = response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].long_name;
                break;
              case "country":
                country = response.results[0].address_components[i].long_name;
                break;
            }
          }
        }
        */
        setShowPreloader(false);
        searchLocationChange({'lat':position.coords.latitude, 'long': position.coords.longitude}, formatted_address) ;
      },
      (error) => {
        console.error(error);
        setShowPreloader(false);
      }
    );
  }

  const handleErrorGeolocation = (err) => {
    console.warn(`ERROR(${err.code}): ${err.message}`)
    setShowPreloader(false)
    ;
  }

  function getCurrentLocation () {
    ReactGA.event('search-by-user-location');
    const options = {
            enableHighAccuracy: false, 
            timeout: 5000,
            maximumAge: 0
    };
      return navigator.geolocation.getCurrentPosition(handleSuccessGeolocation, handleErrorGeolocation, options)
  }
  

  if (navigator.geolocation) {
    // try permissions APIs first as not available on all devices (https://stackoverflow.com/questions/61388198/undefined-is-not-an-object-evaluating-navigator-permissions-query)
    if ( navigator.permissions && navigator.permissions.query) {
      navigator.permissions.query({ name: 'geolocation' })
      .then(function (result) {
        switch (result.state) {
          case 'granted':
            return getCurrentLocation();
          case 'prompt':
            return getCurrentLocation();
          default:
            setShowPreloader(false)
            break;
        }
      });
    }
    else{
      getCurrentLocation();
      }
  }

}