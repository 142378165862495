import * as React from 'react';
import './privacy-policy.css';
import { TitleBlock } from './components/title-block';


function iframe() {
    return {
        __html: '<iframe src="./privacy-policy.html" width="100%" height="800px"></iframe>'
    }
}

export const PrivacyPolicy = (props) => {

    return(
        // react fragment
        <div className='container'>

            <section>

            <div dangerouslySetInnerHTML={iframe()} />


            </section>
        </div>
        
    )
}

