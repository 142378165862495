import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export const CheckoutButton = () => {
    console.log("REACT_APP_STRIPE_PUBLIC_KEY",process.env.REACT_APP_STRIPE_PUBLIC_KEY)
    const handleClick = async () => {
        const stripe = await stripePromise;
        const response = await fetch(process.env.REACT_APP_WILDR_PUBLIC_API + '/stripe/createsession', { method: 'POST' });
        const session = await response.json();
        const result = await stripe.redirectToCheckout({
            sessionId: session.sessionId,
        });
        if (result.error) {
            alert(result.error.message);
        }
    };

    return <button onClick={handleClick}>Subscribe</button>;
};

export default CheckoutButton;