import * as React from 'react';
import './newsletter-signup.css';
import MailchimpSubscribe from "react-mailchimp-subscribe"
import ReactGA from "react-ga4";

const CustomForm = ({ status, closeModal, message, onValidated }) => {

    const [email, setEmail] = React.useState('');
  
    const submit = () =>
      email &&
      email.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email
      });
  
    React.useEffect(() => {
      if (status === "success") {
        clearFields()
        ReactGA.event("newsletter-success-popup", {
        });
      };
    }, [status])
  
  
    const clearFields = () => {
      setEmail('')
      setTimeout(() => {
        closeModal()
      }, 2500)
    }
  
    const handleChange = event => {
      setEmail(event.target.value);
    };
  
  
    return (
        <div className="newsletter-form-nonmodal">
            <div className="email-field-submit-button"
                style={{
                    borderRadius: 2,
                    display: ""
                }}
            >

                <br />
                <input
                    label="Email"
                    onChange={handleChange}
                    // ref={node => (email = node)} breaks with Error: "email" is read-only
                    type="email"
                    value={email}
                    placeholder="your@email.com"
                    isRequired
                    disabled={status === "success"}
                />
                <br />
                <button className="orange-filled-button" style={{ cursor: "pointer" }} onClick={submit} disabled={status === "success"}>
                    Submit
                </button>
                <br />
                {/* 
        <div className='nope' onClick={closeModal}>
          No thanks
        </div>
        */}
            </div>
            <div className="subscribe-status">
                {status === "sending" && <div style={{ color: "" }}>sending...</div>}
                {status === "error" && (
                    <div
                        style={{ color: "red" }}
                        dangerouslySetInnerHTML={{ __html: message }}
                    />
                )}
                {status === "success" && (
                    <div
                        style={{ color: "#ff7001" }}
                        dangerouslySetInnerHTML={{ __html: message }}
                    />
                )}
            </div>
        </div>
    );
};

export const NewsletterSignup = (props) => {
    const [visible, setVisible] = React.useState(true);

    const close = () => setVisible(false);
    const url = "https://wildr.us20.list-manage.com/subscribe/post?u=5744114d84578e062b17229ee&amp;id=cb4ad3dc7b";
    return (
        <div className="container">
            <div className="newsletter">
                <h2>Launching Summer 2024</h2>
                <div className="h2-subtitle">Join our newsletter for an early invite!</div>

                <div className="mailchimp-container">
            
            
                <MailchimpSubscribe 
                    className='mailchimp-signup-module'
                  url={url}
                  render={({ subscribe, status, message }) => (
                    <CustomForm
                      status={status}
                      closeModal={close}
                      message={message}
                      onValidated={formData => subscribe(formData)}
                    />
                  )}
                />
                
                {/* 
                <Mailchimp
                action='https://wildr.us20.list-manage.com/subscribe/post?u=5744114d84578e062b17229ee&amp;id=cb4ad3dc7b'
                fields={[
                {
                    name: 'EMAIL',
                    placeholder: 'Enter you email',
                    type: 'email',
                    required: true,
                },
                ]}
                className='mailchimp-signup-module'
                />
                */}
                </div>
                
                {/* 
                <p className="category-card-x">
                    Adventure inspiration, product updates and great things to do. What more do you need?
                </p>
                */}
            </div>
        </div>
    )
}
