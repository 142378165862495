import React from 'react';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import DirectionsTransitIcon from '@mui/icons-material/DirectionsTransit';
import './journey-times-tp-title.css';

import {ReactComponent as CarIcon} from '../../../assets/icons/car-front.svg';
import {ReactComponent as BikeIcon} from '../../../assets/icons/cycling.svg';
import {ReactComponent as WalkIcon} from '../../../assets/icons/walk.svg';
import {ReactComponent as TransitIcon} from '../../../assets/icons/train.svg';

export const JourneyTimesTpManualTitle = (props) => {
    return (
      <div className='journey-data-tp-title'>
        <div className="journey-data journey-data-formatted">
        
          {/* <div className=''>{props.item.distance.km}km away</div> */}
          {/*'walking' in props.item.travel_time && props.item.travel_time.walking && props.item.travel_time.walking.total_seconds < 3600 
            && <div><WalkIcon className="tranport-mode-icon"/>{props.item.travel_time.walking.human_readable}</div>*/}
          {/* props.showCycling && 'cycling' in props.item.travel_time && props.item.travel_time.cycling && props.item.travel_time.cycling.total_seconds < 3600 
            && <div><BikeIcon className="tranport-mode-icon"/>{props.item.travel_time.cycling.human_readable}</div> */}
          {props.transit_formatted  &&  
            <a href={props.transit_route_url} target="_blank"><TransitIcon className="tranport-mode-icon"/>{props.transit_formatted}</a>
          }
          {props.driving_formatted  &&  
            <a href={props.driving_route_url} target="_blank"><CarIcon className="tranport-mode-icon"/>{props.driving_formatted}</a>
          }
        </div>
      </div>

    )
}