import React from 'react';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import DirectionsTransitIcon from '@mui/icons-material/DirectionsTransit';
import Dotdotdot from 'react-dotdotdot'
import {
  Link,
  useLocation
} from "react-router-dom";


import { ActivityLabels } from "./labels-strip/labels-strip"
import { JourneyTimes } from "./journey-times/journey-times"


function useOnScreen(ref) {
  // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
  // from here, was a hook - botching in to use to see if an activity result is in view or not https://stackoverflow.com/questions/45514676/react-check-if-element-is-visible-in-dom
  const [isIntersecting, setIntersecting] = React.useState(false)

  const observer = new IntersectionObserver(
    ([entry]) => setIntersecting(entry.isIntersecting), { threshold: 1 }
  )

  React.useEffect(() => {
    observer.observe(ref.current)
    // Remove the observer as soon as the component is unmounted
    return () => { observer.disconnect() }
  }, [])

  return isIntersecting
}

export default function ActivityResult(props) {
  const elementRef = React.useRef()
  let location = useLocation();

  const isVisible = useOnScreen(elementRef)

  const imageHeightPx = 400
  // If the hover / highlighted activity changes by moseover marker in the map, scroll to it. If it changes in the list, don't as it introduced unusual scrolling behaviour
  //    it could work but would need some fiddly code so easier to not do it for now
  React.useEffect(() => {
    if (props.hoverActivityId === props.item.id && props.hoverActivityOriginatedInMap) {
      if (isVisible != true) {
        props.handleHighlightedActivityOutOfView(elementRef)
      }
    }

  }, [props.hoverActivityId])
  return (
    <Link to={`/activities/` + props.item.id} className='activity-result-link' ref={elementRef} data-activity-id={props.item.id} onClick={props.clickHandler}
      state={{ background: location, name: props.item.name, travelTime: props.item.travel_time }}
      onMouseEnter={props.activityMouseEnterHandler} onMouseOut={props.activityMouseLeaveHandler}>
      <div className={'activity-result pointer ' + (props.hoverActivityId === props.item.id ? 'activity-result-selected' : '')} >
        <div className="activity-hero-image">
          <img src={props.item.images.length > 0 ? `${props.item.images[0].url}?tr=h-${imageHeightPx}` :
            props.item.external_images.length > 0 ? `${process.env.REACT_APP_EXTERNAL_IMAGE_PROXY_URL}${props.item.external_images[0].url}?tr=h-${imageHeightPx}` : "http://image.com/jejee.jpg"} />
        </div>
        <div className='title-strip'>
          <h2>{props.item.name}</h2>
        </div>

        <ActivityLabels item={props.item} waterQuality={props.waterQuality} />


        <div className='short-description'>{props.item.short_description ? props.item.short_description : props.item.truncated_description}</div>
        {props.item.hasOwnProperty('travel_time') ?
          <JourneyTimes traveTime={props.item.travel_time} /> : ""
        }
        {/*
        <div className='external-link'>
          <a href={props.item.external_url} target="_blank">{props.item.source.slug != "beyonk" ? props.item.source.slug != "gov-bathing" ? "View on " + props.item.source.name: "View full water quality report"  : "Check availability & book"}</a>
        </div> */}

      </div>
      <hr className='result-divider'></hr>
    </Link >
  );
}
