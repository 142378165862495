import * as React from 'react';
import './hero-search.css';
import { EmbeddedSearchBox } from '../location-search/embedded-search-box/embedded-search-box'


export const HeroSearch = (props) => {
    return (
        <div className='header'>
            <section className="hero">
                <h1>Do something wild</h1>
                <p>Thousands of adventurous activities, events and guides in the UK</p>
		        <div className='hero__search-wrapper'>
                    <EmbeddedSearchBox fieldClass='heroSearch'/>
                </div>
            </section>
        </div>
    )
}
