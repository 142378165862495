import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';
import { IconButton, Autocomplete, Link, Grid, Typography, TextField, Box } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import parse from 'autosuggest-highlight/parse'
import throttle from 'lodash/throttle';
import { useLocation } from 'react-router-dom';
import { globalTheme } from '../../../assets/themes/globalTheme';
import { Crosshair } from '../../../assets/icons/Crosshair';
import { getUserGeolocation } from '../../../utils/functions';import { SiteContext } from '../../../utils/contexts';
import ReactGA from "react-ga4";


const autocompleteService = { current: null };


export const GoogleMapsGeoSearch = (props) => {
  const [autocompleteMenuOpen, setAutocompleteMenuOpen] = useState(false);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [placeDetails, setplaceDetails] = useState({});
  const [placeId, setPlaceId] = useState();
  const [options, setOptions] = useState([]);
  const { setShowPreloader } = useContext(SiteContext)

  // Initially taken from here https://mui.com/components/autocomplete/#google-maps-place

  let location = useLocation();  

  // Tutorial on loading a modal or a dedicated page (used for activity detail) here: https://stackblitz.com/github/remix-run/react-router/tree/main/examples/modal?file=src%2FApp.tsx

  
  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        request.componentRestrictions= {
          country: "UK",
        }
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    [],
  );

  useEffect(() => {

    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      // setAutocompleteMenuOpen(false);
      return undefined;
    }
    
    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results]
        }

        setOptions(newOptions);
        // setAutocompleteMenuOpen(true);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);


  const getPlaceDetails = (newValue) => {
    // Todo hadnle if no autocomplete selected and just a search string.
    if (!!newValue) {
      var place_id = newValue.place_id
    } else {
      return
    }
    setPlaceId(place_id)
    var service = new window.google.maps.places.PlacesService(document.createElement('div'));
    service.getDetails({
      placeId: place_id
  }, setLocationCoordsCallback);
  }
  
  function setLocationCoordsCallback(place, status, moreInew) {
  if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        setplaceDetails(place)
      }
  }
  
  useEffect(() => {
    if (props.locationSearchText) {
      setValue(props.locationSearchText); 
      // TODO hide the value "Where are you going here?" dave
    }
    
  }, [props.locationSearchText])

  // If value and place details change, call the passed in searchLocationChange
  // and send some GA data
  React.useEffect(() => {
    if (value && placeDetails){
      // GA - todo move into controller so we can send number of results data // props.handleSearchPlaceDetailsChange(setSearchLocationPlaceDetailsForGaOnly)

      sendGaData(placeDetails)

      props.searchLocationChange({'lat':placeDetails.geometry.location.lat(), 'long': placeDetails.geometry.location.lng()}, value.description)
    }

  }, [placeDetails])

  const sendGaData = (placeDetails) => {

    // GA stuff - if location info we store general area info
    var postal_town = ""
    var administrative_area_level_2 = ""
    var administrative_area_level_1 = ""
    var vicinity = ""
    var locality = ""
    var country = ""

    var place_postal_towns = placeDetails.address_components.filter(addressComponent => addressComponent.types[0] === 'postal_town')
    if (place_postal_towns.length > 0){postal_town = place_postal_towns[0].long_name}

    var administrative_areas2 = placeDetails.address_components.filter(addressComponent => addressComponent.types[0] === 'administrative_area_level_2')
    if (administrative_areas2.length > 0){administrative_area_level_2 = administrative_areas2[0].long_name}

    var administrative_areas1 = placeDetails.address_components.filter(addressComponent => addressComponent.types[0] === 'administrative_area_level_1')
    if (administrative_areas1.length > 0){administrative_area_level_1 = administrative_areas1[0].long_name}

    var localities = placeDetails.address_components.filter(addressComponent => addressComponent.types[0] === 'locality')
    if (localities.length > 0){locality = localities[0].long_name}

    var countires = placeDetails.address_components.filter(addressComponent => addressComponent.types[0] === 'country')
    if (countires.length > 0){country = countires[0].long_name}

    if(placeDetails.vicinity){vicinity = placeDetails.vicinity}

    ReactGA.event("search", {
      search_term: value.description,
      lat: placeDetails.geometry.location.lat(),
      long: placeDetails.geometry.location.lng(),
      coords: [placeDetails.geometry.location.lat(), placeDetails.geometry.location.lng()],
      location_id: placeId,
      postal_town: postal_town,
      administrative_area_level_2: administrative_area_level_2,
      vicinity: vicinity,
      locality: locality,
      country: country,
      administrative_area_level_1: administrative_area_level_1,
      selected_category_slugs: props.selectedCategorySlugs ? props.selectedCategorySlugs : null
    });
  }
  return (
    <React.Fragment>
      
    <div className='location-search'>
      <Autocomplete
        className={props.fieldClass}
        id="google-map-demo"
        // open={autocompleteMenuOpen}
        freeSolo={inputValue ? false : true}
        // componentRestrictions={ {country: "us" }} // DS messing. 
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
        // getOptionSelected={(option, value) => option.iso === value.iso} // might fix the error in console
        hiddenlabel={"true"} // dont think supported and causes warning
        filterOptions={(x) => x}
        options={options}
        autoHighlight={true}
        includeInputInList
        filterSelectedOptions
        noOptionsText={"No results found"}
        value={value}
        fullWidth={true}
        onChange={(event, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
          // If new value exists, set lat and long, then in callback refresh activities.
          if (newValue !== null) {
            // Get place details inc lat and long, callback in here also sets the LocationCoords state
            getPlaceDetails(newValue)
          }
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <Box
            component='div'
            sx={{
              position: 'relative',
            }}
          >
            <TextField
              {...params}
              variant="outlined"
              placeholder={props.placeholder ? props.placeholder : "Where are you heading?"}
              InputLabelProps={{shrink: false}}
              fullWidth
            />

            {props.fieldClass === 'heroSearch' ? (
              <IconButton
                className='getLocation'
                aria-label='get user location'
                onClick={() => {
                  setShowPreloader(true);
                  getUserGeolocation(props.searchLocationChange, setShowPreloader);
                }}
              >
                <Crosshair size={24} color='#FFF' />
              </IconButton>)
            : (
              <IconButton
                className='getLocationSmall'
                aria-label='get user location'
                onClick={() => {
                  setShowPreloader(true);
                  getUserGeolocation(props.searchLocationChange, setShowPreloader);
                }}
              >
                <Crosshair size={22} color='grey'/>
              </IconButton>
            )}
          </Box>
          
        )}
        renderOption={(props, option) => {
          if ( option.structured_formatting){
            const matches = option.structured_formatting.main_text_matched_substrings;
            const parts = parse(
              option.structured_formatting.main_text,
              matches.map((match) => [match.offset, match.offset + match.length]),
            );

            return (
              <Link key={option.structured_formatting.main_text} {...props} underline='none'>
                <Grid container alignItems="center">
                  <Grid item>
                    <LocationOnIcon
                      sx={{
                        color: globalTheme.palette.text.secondary,
                        marginRight: globalTheme.spacing(2)
                      }}
                    />
                  </Grid>
                  <Grid item xs>
                    {parts.map((part) => (
                      <span key={part.text} style={{ fontWeight: part.highlight ? 700 : 400, color: '#2D3945' }}>
                        {part.text}
                      </span>
                    ))}
                    <Typography variant="body2" color="textSecondary">
                      {option.structured_formatting.secondary_text}
                    </Typography>
                  </Grid>
                </Grid>
              </Link>
            );
          }
          
        }}
      />
      </div></React.Fragment>
  );
}