import React from 'react';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import DirectionsTransitIcon from '@mui/icons-material/DirectionsTransit';
import './journey-times-tp-activity.css';

import {ReactComponent as CarIcon} from '../../../assets/icons/car-front.svg';
import {ReactComponent as BikeIcon} from '../../../assets/icons/cycling.svg';
import {ReactComponent as WalkIcon} from '../../../assets/icons/walk.svg';
import {ReactComponent as TransitIcon} from '../../../assets/icons/train.svg';

export const JourneyTimesTpActivity = (props) => {
  return (
      <div>
        <div className="journey-data journey-data-formatted">
          {/* <div className=''>{props.item.distance.km}km away</div> */}
          {'walking' in props.travelTime && props.travelTime.walking && props.travelTime.walking.total_seconds < 3600 
            && <div><WalkIcon className="tranport-mode-icon"/>{props.travelTime.walking.human_readable}</div>}
          {props.showCycling && 'cycling' in props.travelTime && props.travelTime.cycling && props.travelTime.cycling.total_seconds < 3600 
            && <div><BikeIcon className="tranport-mode-icon"/>{props.travelTime.cycling.human_readable}</div>}
          <div><CarIcon className="tranport-mode-icon"/>{props.travelTime.driving.human_readable}</div>
          {'transit' in props.travelTime && props.travelTime.transit && props.travelTime.transit.total_seconds < 3600
            && <div><TransitIcon className="tranport-mode-icon"/>{props.travelTime.transit.human_readable}</div>}
        </div>
      </div>
    )
}