import * as React from 'react';
import {MapContainer, TileLayer, Marker} from 'react-leaflet'
import L from 'leaflet';
import {useMapEvents} from "react-leaflet";

var activityIcon = L.Icon.extend({ 
  options: {
    iconSize: [30, 42],
    iconAnchor:   [15, 42], // point of the icon which will correspond to marker's location
    // popupAnchor: [0, -30]
  }
});
var selectedActivityIcon = L.Icon.extend({ 
  options: {
    iconSize: [41, 57],
    iconAnchor:   [20, 57], // point of the icon which will correspond to marker's location
    // popupAnchor: [0, -30] // TODO FIX
  }
});

export const mapIcons = {
    'wild-swim': new activityIcon({iconUrl: `/map_icons/normal/icons8-swimming-30.svg`, iconSize: [30, 42]}), // Kept as an example of custom icon size
    'official-bathing-spots': new activityIcon({iconUrl: `/map_icons/normal/icons8-swimming-30.svg`}), // Kept as an example of custom icon size
    'beyonk-temp': new activityIcon({iconUrl: `/map_icons/normal/icons8-asterisk-30.svg`}),
    'blank': new activityIcon({iconUrl: `/map_icons/normal/icons8-asterisk-30.svg`}),
    'walk': new activityIcon({iconUrl: `/map_icons/normal/icons8-trekking-30.svg`}),
    'hike': new activityIcon({iconUrl: `/map_icons/normal/icons8-trekking-30.svg`}),
    'culture': new activityIcon({iconUrl: `/map_icons/normal/icons8-monocle-30.svg`}),
    'historical': new activityIcon({iconUrl: `/map_icons/normal/icons8-archeology-30.svg`}),
    'wildlife': new activityIcon({iconUrl: `/map_icons/normal/icons8-running-rabbit-30.svg`}),
    'culinary': new activityIcon({iconUrl: `/map_icons/normal/icons8-cutlery-30.svg`}),
    'mindfulness': new activityIcon({iconUrl: `/map_icons/normal/icons8-meditation-30.svg`}),
    'camp': new activityIcon({iconUrl: `/map_icons/normal/icons8-camping-tent-30.svg`}),
    'crafts': new activityIcon({iconUrl: `/map_icons/normal/icons8-needle-30.svg`}),
    'tours': new activityIcon({iconUrl: `/map_icons/normal/icons8-tourist-guide-30.svg`}),
    'canoe': new activityIcon({iconUrl: `/map_icons/normal/icons8-kayaking-30.svg`}),
    'sup': new activityIcon({iconUrl: `/map_icons/normal/icons8-sup-30.svg`}),
    'scuba-dive': new activityIcon({iconUrl: `/map_icons/normal/icons8-scuba-diving-30.svg`}),
    'climb': new activityIcon({iconUrl: `/map_icons/normal/icons8-climbing-30.svg`}),
    'hot-air-ballooning': new activityIcon({iconUrl: `/map_icons/normal/icons8-hot-air-balloon-30.svg`}),
    'bike': new activityIcon({iconUrl: `/map_icons/normal/icons8-cycling-30.svg`}),
    'sail-boat': new activityIcon({iconUrl: `/map_icons/normal/icons8-sail-boat-30.svg`}),
    'bushcraft': new activityIcon({iconUrl: `/map_icons/normal/icons8-campfire-30.svg`}),
    'surf': new activityIcon({iconUrl: `/map_icons/normal/icons8-surfing-30.svg`}),
    'ski': new activityIcon({iconUrl: `/map_icons/normal/icons8-skiing-30.svg`}),
    'mtb': new activityIcon({iconUrl: `/map_icons/normal/icons8-cycling-mountain-bike-30.svg`}),
  }
  export const mapIconsSelected = {
    'wild-swim': new selectedActivityIcon({iconUrl: `/map_icons/selected/icons8-swimming-30.svg`, iconSize: [41, 57]}), // Kept as an example of custom icon size
    'official-bathing-spots': new selectedActivityIcon({iconUrl: `/map_icons/selected/icons8-swimming-30.svg`}),
    'beyonk-temp': new selectedActivityIcon({iconUrl: `/map_icons/selected/icons8-asterisk-30.svg`}),
    'blank': new selectedActivityIcon({iconUrl: `/map_icons/selected/icons8-asterisk-30.svg`}),
    'walk': new selectedActivityIcon({iconUrl: `/map_icons/selected/icons8-trekking-30.svg`}),
    'hike': new selectedActivityIcon({iconUrl: `/map_icons/selected/icons8-trekking-30.svg`}),
    'culture': new selectedActivityIcon({iconUrl: `/map_icons/selected/icons8-monocle-30.svg`}),
    'historical': new selectedActivityIcon({iconUrl: `/map_icons/selected/icons8-archeology-30.svg`}),
    'wildlife': new selectedActivityIcon({iconUrl: `/map_icons/selected/icons8-running-rabbit-30.svg`}),
    'culinary': new selectedActivityIcon({iconUrl: `/map_icons/selected/icons8-cutlery-30.svg`}),
    'mindfulness': new selectedActivityIcon({iconUrl: `/map_icons/selected/icons8-meditation-30.svg`}),
    'camp': new selectedActivityIcon({iconUrl: `/map_icons/selected/icons8-camping-tent-30.svg`}),
    'crafts': new selectedActivityIcon({iconUrl: `/map_icons/selected/icons8-needle-30.svg`}),
    'tours': new selectedActivityIcon({iconUrl: `/map_icons/selected/icons8-tourist-guide-30.svg`}),
    'canoe': new selectedActivityIcon({iconUrl: `/map_icons/selected/icons8-kayaking-30.svg`}),
    'sup': new selectedActivityIcon({iconUrl: `/map_icons/selected/icons8-sup-30.svg`}),
    'scuba-dive': new selectedActivityIcon({iconUrl: `/map_icons/selected/icons8-scuba-diving-30.svg`}),
    'climb': new selectedActivityIcon({iconUrl: `/map_icons/selected/icons8-climbing-30.svg`}),
    'hot-air-ballooning': new selectedActivityIcon({iconUrl: `/map_icons/selected/icons8-hot-air-balloon-30.svg`}),
    'bike': new selectedActivityIcon({iconUrl: `/map_icons/selected/icons8-cycling-30.svg`}),
    'sail-boat': new selectedActivityIcon({iconUrl: `/map_icons/selected/icons8-sail-boat-30.svg`}),
    'bushcraft': new selectedActivityIcon({iconUrl: `/map_icons/selected/icons8-campfire-30.svg`}),
    'surf': new selectedActivityIcon({iconUrl: `/map_icons/selected/icons8-surfing-30.svg`}),
    'ski': new selectedActivityIcon({iconUrl: `/map_icons/selected/icons8-skiing-30.svg`}),
    'mtb': new selectedActivityIcon({iconUrl: `/map_icons/selected/icons8-cycling-mountain-bike-30.svg`}),
  }